import React from 'react';
import hoistNonReactStatics from 'hoist-non-react-statics';
import { Layout } from '~components';
import { getDisplayName } from './getDisplayName';

export function withLayout(Component: any) {
  const LayoutComponent = ({ footer, navigation, ...props }) => {
    return (
      <Layout footer={footer} navigation={navigation}>
        <Component {...props} />
      </Layout>
    );
  };

  hoistNonReactStatics(LayoutComponent, Component);

  LayoutComponent.displayName = `withLayout(${getDisplayName(Component)})`;

  return LayoutComponent;
}
