import { storyblokSdk } from './storyblok/client';

export const fetchResults = async ({ searchTerm, perPage, page }) => {
  const { items, total } = (
    await storyblokSdk.search({
      searchTerm,
      perPage,
      page,
    })
  ).ContentNodes;

  const lastPage = page === Math.ceil(total / perPage);
  const moreItems = lastPage ? 0 : total - page * perPage;

  return { items, total, moreItems };
};
