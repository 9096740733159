import { ReactNode } from 'react';

import { InputWrapperProps, Box, StatusMessage } from '@storyofams/react-ui';
import { pick } from '@styled-system/props';
import { Text } from '../Text';

export const InputWrapper = ({
  label,
  status,
  statusMessage,
  error,
  disabled,
  id,
  children,
  ...props
}: InputWrapperProps & { disabled?: boolean; children: ReactNode }) => {
  return (
    <Box {...pick(props)}>
      {label && (
        <Text
          as="label"
          fontWeight="extraBold"
          mb={1.5}
          htmlFor={id}
          position="relative"
          color={disabled && 'grey300'}
        >
          {label}
        </Text>
      )}

      {children}

      {(!!statusMessage || error) && (
        <StatusMessage
          status={error ? 'error' : status}
          mt={1.5}
          color={error ? 'error600' : 'grey500'}
        >
          {error || statusMessage}
        </StatusMessage>
      )}
    </Box>
  );
};
