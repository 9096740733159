import { useState } from 'react';
import { Box, Spinner } from '@storyofams/react-ui';
import axios from 'axios';
import { useForm } from 'react-hook-form';

import styled from 'styled-components';
import { Heading, Input, Text } from '~components';
import { SubmitArrow } from '../Icons';

type NewsletterFormProps = {
  cta?: string;
  placeholder?: string;
  description?: string;
};

const PurpleSpinner = styled(Spinner)`
  color: ${({ theme }) => theme.colors.primary};
`;

export const NewsletterForm = ({
  cta,
  placeholder,
  description,
}: NewsletterFormProps) => {
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  const {
    register,
    handleSubmit,
    reset,
    formState: { isSubmitting },
  } = useForm();

  const onSubmit = async (input) => {
    setError('');
    setSuccess('');

    try {
      await axios.post('/api/newsletter', {
        email: input.email,
      });
      setSuccess('Subscribed!');
      reset();
    } catch (e) {
      setError(e?.message);
    }
  };

  return (
    <Box
      as="form"
      width={['100%', '50%', '50%', '33%']}
      onSubmit={handleSubmit(onSubmit)}
    >
      <Heading variant="h4" as="h4" mb={3} fontWeight="bold">
        {cta}
      </Heading>
      <Input
        placeholder={placeholder}
        mb={2}
        icon={isSubmitting ? PurpleSpinner : SubmitArrow}
        onSubmit={handleSubmit(onSubmit)}
        required
        {...register('email', { required: true })}
        defaultValue=""
      />
      <Text>{description}</Text>
      {(success || error) && (
        <Text
          fontSize="16px"
          lineHeight="130%"
          color="primary"
          mt={success || error ? 3 : 0}
          borderRadius="sm"
        >
          <span submit-success="">{success}</span>
          <span submit-error="">{error}</span>
        </Text>
      )}
    </Box>
  );
};
