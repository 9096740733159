import { ReactNode, useState } from 'react';
import {
  Box,
  Flex,
  Stack,
  SystemProps,
  Button,
  Icon,
} from '@storyofams/react-ui';

import SbEditable from 'storyblok-react';
import { SearchBar } from '~components';
import { getLinkProps } from '~lib';
import { NavigationItem, FooterItem } from '~lib/storyblok/sdk';

import { Hamburger, Logo, LogoPpf } from '../Icons';
import { Navigation } from '../Navigation';
import { NavigationLink } from '../Navigation/components/NavigationLink';
import { NavigationPrimary } from '../Navigation/components/NavigationPrimary';
import { Text } from '../Text';
import { Footer } from './Footer';

interface LayoutProps extends SystemProps {
  children: ReactNode;
  navigation: NavigationItem;
  footer: FooterItem;
}

export const Layout = ({
  children,
  footer,
  navigation,
  ...props
}: LayoutProps) => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  return (
    <Flex flexDirection="column" width="100%" px={4}>
      <Flex
        width="100%"
        height="84px"
        alignItems="center"
        justifyContent="space-between"
        borderBottom="1px"
        borderColor="grey100"
        marginBottom={3}
        position="sticky"
        top={0}
        backgroundColor="white"
        zIndex={[5, 5, 5, 10]}
      >
        <Button as="a" variant="link" to="/">
          <img src="/favicons/lantern-blue.png" height="40" style={{
            height: '40px',
          }} />
          <Box
            height="40px"
            borderRight="1px"
            borderRightColor="grey100"
            mx={2}
            display={['none', 'block']}
          />
        </Button>

        <SearchBar />

        <Box
          textColor="grey700"
          fontSize={1.5}
          display={['none', 'none', 'none', 'block']}
        >
          {/* V. 2.3.1 */}
        </Box>

        <Box
          as="button"
          border="1px"
          borderColor="grey100"
          borderRadius="sm"
          height="40px"
          width="40px"
          padding={1.75}
          display={['flex', 'flex', 'flex', 'none']}
          justifyContent="center"
          alignItems="center"
          onClick={() => setMobileMenuOpen(true)}
        >
          <Icon icon={Hamburger} />
        </Box>
      </Flex>

      <Flex>
        <Navigation
          zIndex={7}
          width={['0', '0', '0', '20%']}
          borderRight={['none', 'none', 'none', '1px']}
          borderRightColor={[
            'transparent',
            'transparent',
            'transparent',
            'grey100',
          ]}
        >
          <NavigationPrimary
            mobileMenuOpen={mobileMenuOpen}
            setMobileMenuOpen={setMobileMenuOpen}
          >
            <SbEditable content={(navigation?.content as any) || {}}>
              <Stack
                space={[2.5, 2.5, 2.5, 2]}
                flexDirection="column"
                pb={3}
                borderBottom={['1px', '1px', '1px', 'none']}
                borderColor={['grey100', 'grey100', 'grey100', 'transparent']}
              >
                {navigation?.content?.link_list?.map(
                  ({ label, url, icon, _uid }) => {
                    const html = icon?.content[0]?.content[0]?.text;

                    return (
                      <NavigationLink key={_uid} link={getLinkProps(url)}>
                        <Flex
                          justifyContent="center"
                          alignContent="center"
                          width="32px"
                          height="32px"
                          backgroundColor="primary"
                          borderRadius="sm"
                          padding={0.75}
                          marginRight={1.25}
                        >
                          <Box
                            dangerouslySetInnerHTML={{ __html: html }}
                            width="100%"
                            height="100%"
                          />
                        </Flex>
                        {label}
                      </NavigationLink>
                    );
                  },
                )}
              </Stack>

              <Stack space={[3, 2.5]} flexDirection="column" mt={[3, 5]}>
                <Text variant="kicker" mb={[1, 0.5]}>
                  Guides
                </Text>
                {navigation?.content?.guides?.map(({ label, url, _uid }) => (
                  <NavigationLink
                    key={_uid}
                    link={getLinkProps(url)}
                    color="grey200"
                  >
                    {label}
                  </NavigationLink>
                ))}
              </Stack>

              <Stack space={[3, 2.5]} flexDirection="column" mt={5}>
                <Text variant="kicker" mb={[1, 0.5]}>
                  Links
                </Text>
                {navigation?.content?.links?.map(
                  ({ label, url, target, _uid }) => (
                    <NavigationLink
                      key={_uid}
                      link={getLinkProps(url)}
                      color="grey200"
                      target={target}
                    >
                      {label}
                    </NavigationLink>
                  ),
                )}
              </Stack>
            </SbEditable>
          </NavigationPrimary>
        </Navigation>

        <Box
          as="main"
          flexDirection="column"
          flex="1"
          minHeight={[
            'calc(100vh - 52px)',
            'calc(100vh - 52px)',
            'calc(100vh - 80px)',
          ]}
          {...props}
        >
          {children}
        </Box>
      </Flex>
      {footer && <Footer {...footer} />}
    </Flex>
  );
};
