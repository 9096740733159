import { Box, SystemProps, system } from '@storyofams/react-ui';
import styled from 'styled-components';
import { variant } from 'styled-system';

export const textVariants = {
  m: {
    fontSize: 2,
    color: 'grey400',
  },
  link: {
    fontSize: 2,
    textDecoration: 'underline',
    color: 'primary',
  },
  kicker: {
    fontSize: [1.75, 1.5],
    textTransform: 'uppercase',
    lineHeight: 1,
  },
  menuItem: {
    fontSize: 2,
    color: 'grey600',
  },
};

type CustomProps = {
  /** defaults to `p` */
  as?: 'p' | 'span' | 'blockquote' | 'strong' | 'em' | 'pre' | 'label' | 'a';
  variant?: keyof typeof textVariants;
} & SystemProps;

export const Text = styled(Box).attrs((props: CustomProps) => ({
  as: props?.as ?? 'p',
  variant: props?.variant || 'm',
}))<CustomProps>`
  font-family: ${(p) => p.theme.fonts.body};
  color: ${(p) => p.theme.colors.grey800};
  line-height: ${(p) => p.theme.lineHeights.medium};

  ${variant({ variants: textVariants })}
  ${system}
`;
