import { GraphQLClient } from 'graphql-request';
import * as Dom from 'graphql-request/dist/types.dom';
import gql from 'graphql-tag';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  BlockScalar: any;
  /** An ISO 8601-encoded datetime */
  ISO8601DateTime: any;
  JsonScalar: any;
};

export type Alternate = {
  __typename?: 'Alternate';
  fullSlug: Scalars['String'];
  id: Scalars['Int'];
  isFolder?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  parentId?: Maybe<Scalars['Int']>;
  published: Scalars['Boolean'];
  slug: Scalars['String'];
};

export type Asset = {
  __typename?: 'Asset';
  alt?: Maybe<Scalars['String']>;
  copyright?: Maybe<Scalars['String']>;
  filename: Scalars['String'];
  focus?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};


export type ContentItem = {
  __typename?: 'ContentItem';
  alternates?: Maybe<Array<Maybe<Alternate>>>;
  content?: Maybe<Scalars['JsonScalar']>;
  content_string?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['String']>;
  default_full_slug?: Maybe<Scalars['String']>;
  first_published_at?: Maybe<Scalars['String']>;
  full_slug?: Maybe<Scalars['String']>;
  group_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  is_startpage?: Maybe<Scalars['Boolean']>;
  lang?: Maybe<Scalars['String']>;
  meta_data?: Maybe<Scalars['JsonScalar']>;
  name?: Maybe<Scalars['String']>;
  parent_id?: Maybe<Scalars['Int']>;
  path?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['Int']>;
  published_at?: Maybe<Scalars['String']>;
  release_id?: Maybe<Scalars['Int']>;
  slug?: Maybe<Scalars['String']>;
  sort_by_date?: Maybe<Scalars['String']>;
  tag_list?: Maybe<Array<Maybe<Scalars['String']>>>;
  translated_slugs?: Maybe<Array<Maybe<TranslatedSlug>>>;
  uuid?: Maybe<Scalars['String']>;
};

export type ContentItems = {
  __typename?: 'ContentItems';
  items?: Maybe<Array<Maybe<ContentItem>>>;
  total?: Maybe<Scalars['Int']>;
};

export type Datasource = {
  __typename?: 'Datasource';
  id: Scalars['Int'];
  name: Scalars['String'];
  slug: Scalars['String'];
};

export type DatasourceEntries = {
  __typename?: 'DatasourceEntries';
  items: Array<DatasourceEntry>;
  total: Scalars['Int'];
};

export type DatasourceEntry = {
  __typename?: 'DatasourceEntry';
  dimensionValue?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  name: Scalars['String'];
  value: Scalars['String'];
};

export type Datasources = {
  __typename?: 'Datasources';
  items: Array<Datasource>;
};

export type FaqComponent = {
  __typename?: 'FaqComponent';
  _editable?: Maybe<Scalars['String']>;
  _uid?: Maybe<Scalars['String']>;
  component?: Maybe<Scalars['String']>;
  questions?: Maybe<Scalars['BlockScalar']>;
  seo?: Maybe<Scalars['JsonScalar']>;
  title?: Maybe<Scalars['String']>;
};

export type FaqFilterQuery = {
  title?: Maybe<FilterQueryOperations>;
};

export type FaqItem = {
  __typename?: 'FaqItem';
  alternates?: Maybe<Array<Maybe<Alternate>>>;
  content?: Maybe<FaqComponent>;
  created_at?: Maybe<Scalars['String']>;
  default_full_slug?: Maybe<Scalars['String']>;
  first_published_at?: Maybe<Scalars['String']>;
  full_slug?: Maybe<Scalars['String']>;
  group_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  is_startpage?: Maybe<Scalars['Boolean']>;
  lang?: Maybe<Scalars['String']>;
  meta_data?: Maybe<Scalars['JsonScalar']>;
  name?: Maybe<Scalars['String']>;
  parent_id?: Maybe<Scalars['Int']>;
  path?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['Int']>;
  published_at?: Maybe<Scalars['String']>;
  release_id?: Maybe<Scalars['Int']>;
  slug?: Maybe<Scalars['String']>;
  sort_by_date?: Maybe<Scalars['String']>;
  tag_list?: Maybe<Array<Maybe<Scalars['String']>>>;
  translated_slugs?: Maybe<Array<Maybe<TranslatedSlug>>>;
  uuid?: Maybe<Scalars['String']>;
};

export type FaqItems = {
  __typename?: 'FaqItems';
  items?: Maybe<Array<Maybe<FaqItem>>>;
  total?: Maybe<Scalars['Int']>;
};

export type FilterQueryOperations = {
  /** Matches exactly one value */
  in?: Maybe<Scalars['String']>;
  /** Matches all without the given value */
  not_in?: Maybe<Scalars['String']>;
  /** Matches exactly one value with a wildcard search using * */
  like?: Maybe<Scalars['String']>;
  /** Matches all without the given value */
  not_like?: Maybe<Scalars['String']>;
  /** Matches any value of given array */
  in_array?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Must match all values of given array */
  all_in_array?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Greater than date (Exmples: 2019-03-03 or 2020-03-03T03:03:03) */
  gt_date?: Maybe<Scalars['ISO8601DateTime']>;
  /** Less than date (Format: 2019-03-03 or 2020-03-03T03:03:03) */
  lt_date?: Maybe<Scalars['ISO8601DateTime']>;
  /** Greater than integer value */
  gt_int?: Maybe<Scalars['Int']>;
  /** Less than integer value */
  lt_int?: Maybe<Scalars['Int']>;
  /** Matches exactly one integer value */
  in_int?: Maybe<Scalars['Int']>;
  /** Greater than float value */
  gt_float?: Maybe<Scalars['Float']>;
  /** Less than float value */
  lt_float?: Maybe<Scalars['Float']>;
};

export type FiltersComponent = {
  __typename?: 'FiltersComponent';
  _editable?: Maybe<Scalars['String']>;
  _uid?: Maybe<Scalars['String']>;
  component?: Maybe<Scalars['String']>;
  tag_groups?: Maybe<Scalars['BlockScalar']>;
};

export type FiltersItem = {
  __typename?: 'FiltersItem';
  alternates?: Maybe<Array<Maybe<Alternate>>>;
  content?: Maybe<FiltersComponent>;
  created_at?: Maybe<Scalars['String']>;
  default_full_slug?: Maybe<Scalars['String']>;
  first_published_at?: Maybe<Scalars['String']>;
  full_slug?: Maybe<Scalars['String']>;
  group_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  is_startpage?: Maybe<Scalars['Boolean']>;
  lang?: Maybe<Scalars['String']>;
  meta_data?: Maybe<Scalars['JsonScalar']>;
  name?: Maybe<Scalars['String']>;
  parent_id?: Maybe<Scalars['Int']>;
  path?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['Int']>;
  published_at?: Maybe<Scalars['String']>;
  release_id?: Maybe<Scalars['Int']>;
  slug?: Maybe<Scalars['String']>;
  sort_by_date?: Maybe<Scalars['String']>;
  tag_list?: Maybe<Array<Maybe<Scalars['String']>>>;
  translated_slugs?: Maybe<Array<Maybe<TranslatedSlug>>>;
  uuid?: Maybe<Scalars['String']>;
};

export type FiltersItems = {
  __typename?: 'FiltersItems';
  items?: Maybe<Array<Maybe<FiltersItem>>>;
  total?: Maybe<Scalars['Int']>;
};

export type FooterComponent = {
  __typename?: 'FooterComponent';
  _editable?: Maybe<Scalars['String']>;
  _uid?: Maybe<Scalars['String']>;
  component?: Maybe<Scalars['String']>;
  icon_links?: Maybe<Scalars['BlockScalar']>;
  input_placeholder?: Maybe<Scalars['String']>;
  link_list?: Maybe<Scalars['BlockScalar']>;
  signup_cta?: Maybe<Scalars['String']>;
  signup_description?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type FooterFilterQuery = {
  title?: Maybe<FilterQueryOperations>;
  signup_cta?: Maybe<FilterQueryOperations>;
  input_placeholder?: Maybe<FilterQueryOperations>;
  signup_description?: Maybe<FilterQueryOperations>;
};

export type FooterItem = {
  __typename?: 'FooterItem';
  alternates?: Maybe<Array<Maybe<Alternate>>>;
  content?: Maybe<FooterComponent>;
  created_at?: Maybe<Scalars['String']>;
  default_full_slug?: Maybe<Scalars['String']>;
  first_published_at?: Maybe<Scalars['String']>;
  full_slug?: Maybe<Scalars['String']>;
  group_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  is_startpage?: Maybe<Scalars['Boolean']>;
  lang?: Maybe<Scalars['String']>;
  meta_data?: Maybe<Scalars['JsonScalar']>;
  name?: Maybe<Scalars['String']>;
  parent_id?: Maybe<Scalars['Int']>;
  path?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['Int']>;
  published_at?: Maybe<Scalars['String']>;
  release_id?: Maybe<Scalars['Int']>;
  slug?: Maybe<Scalars['String']>;
  sort_by_date?: Maybe<Scalars['String']>;
  tag_list?: Maybe<Array<Maybe<Scalars['String']>>>;
  translated_slugs?: Maybe<Array<Maybe<TranslatedSlug>>>;
  uuid?: Maybe<Scalars['String']>;
};

export type FooterItems = {
  __typename?: 'FooterItems';
  items?: Maybe<Array<Maybe<FooterItem>>>;
  total?: Maybe<Scalars['Int']>;
};

export type GuideComponent = {
  __typename?: 'GuideComponent';
  _editable?: Maybe<Scalars['String']>;
  _uid?: Maybe<Scalars['String']>;
  body?: Maybe<Scalars['BlockScalar']>;
  component?: Maybe<Scalars['String']>;
  seo?: Maybe<Scalars['JsonScalar']>;
};

export type GuideItem = {
  __typename?: 'GuideItem';
  alternates?: Maybe<Array<Maybe<Alternate>>>;
  content?: Maybe<GuideComponent>;
  created_at?: Maybe<Scalars['String']>;
  default_full_slug?: Maybe<Scalars['String']>;
  first_published_at?: Maybe<Scalars['String']>;
  full_slug?: Maybe<Scalars['String']>;
  group_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  is_startpage?: Maybe<Scalars['Boolean']>;
  lang?: Maybe<Scalars['String']>;
  meta_data?: Maybe<Scalars['JsonScalar']>;
  name?: Maybe<Scalars['String']>;
  parent_id?: Maybe<Scalars['Int']>;
  path?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['Int']>;
  published_at?: Maybe<Scalars['String']>;
  release_id?: Maybe<Scalars['Int']>;
  slug?: Maybe<Scalars['String']>;
  sort_by_date?: Maybe<Scalars['String']>;
  tag_list?: Maybe<Array<Maybe<Scalars['String']>>>;
  translated_slugs?: Maybe<Array<Maybe<TranslatedSlug>>>;
  uuid?: Maybe<Scalars['String']>;
};

export type GuideItems = {
  __typename?: 'GuideItems';
  items?: Maybe<Array<Maybe<GuideItem>>>;
  total?: Maybe<Scalars['Int']>;
};

export type HomeComponent = {
  __typename?: 'HomeComponent';
  _editable?: Maybe<Scalars['String']>;
  _uid?: Maybe<Scalars['String']>;
  cards?: Maybe<Scalars['BlockScalar']>;
  component?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  seo?: Maybe<Scalars['JsonScalar']>;
  title?: Maybe<Scalars['String']>;
};

export type HomeFilterQuery = {
  title?: Maybe<FilterQueryOperations>;
  description?: Maybe<FilterQueryOperations>;
};

export type HomeItem = {
  __typename?: 'HomeItem';
  alternates?: Maybe<Array<Maybe<Alternate>>>;
  content?: Maybe<HomeComponent>;
  created_at?: Maybe<Scalars['String']>;
  default_full_slug?: Maybe<Scalars['String']>;
  first_published_at?: Maybe<Scalars['String']>;
  full_slug?: Maybe<Scalars['String']>;
  group_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  is_startpage?: Maybe<Scalars['Boolean']>;
  lang?: Maybe<Scalars['String']>;
  meta_data?: Maybe<Scalars['JsonScalar']>;
  name?: Maybe<Scalars['String']>;
  parent_id?: Maybe<Scalars['Int']>;
  path?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['Int']>;
  published_at?: Maybe<Scalars['String']>;
  release_id?: Maybe<Scalars['Int']>;
  slug?: Maybe<Scalars['String']>;
  sort_by_date?: Maybe<Scalars['String']>;
  tag_list?: Maybe<Array<Maybe<Scalars['String']>>>;
  translated_slugs?: Maybe<Array<Maybe<TranslatedSlug>>>;
  uuid?: Maybe<Scalars['String']>;
};

export type HomeItems = {
  __typename?: 'HomeItems';
  items?: Maybe<Array<Maybe<HomeItem>>>;
  total?: Maybe<Scalars['Int']>;
};


export type IntegrationpageComponent = {
  __typename?: 'IntegrationpageComponent';
  _editable?: Maybe<Scalars['String']>;
  _uid?: Maybe<Scalars['String']>;
  bg?: Maybe<Asset>;
  component?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['JsonScalar']>;
  seo?: Maybe<Scalars['JsonScalar']>;
  shortDescription?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type IntegrationpageFilterQuery = {
  title?: Maybe<FilterQueryOperations>;
  shortDescription?: Maybe<FilterQueryOperations>;
};

export type IntegrationpageItem = {
  __typename?: 'IntegrationpageItem';
  alternates?: Maybe<Array<Maybe<Alternate>>>;
  content?: Maybe<IntegrationpageComponent>;
  created_at?: Maybe<Scalars['String']>;
  default_full_slug?: Maybe<Scalars['String']>;
  first_published_at?: Maybe<Scalars['String']>;
  full_slug?: Maybe<Scalars['String']>;
  group_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  is_startpage?: Maybe<Scalars['Boolean']>;
  lang?: Maybe<Scalars['String']>;
  meta_data?: Maybe<Scalars['JsonScalar']>;
  name?: Maybe<Scalars['String']>;
  parent_id?: Maybe<Scalars['Int']>;
  path?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['Int']>;
  published_at?: Maybe<Scalars['String']>;
  release_id?: Maybe<Scalars['Int']>;
  slug?: Maybe<Scalars['String']>;
  sort_by_date?: Maybe<Scalars['String']>;
  tag_list?: Maybe<Array<Maybe<Scalars['String']>>>;
  translated_slugs?: Maybe<Array<Maybe<TranslatedSlug>>>;
  uuid?: Maybe<Scalars['String']>;
};

export type IntegrationpageItems = {
  __typename?: 'IntegrationpageItems';
  items?: Maybe<Array<Maybe<IntegrationpageItem>>>;
  total?: Maybe<Scalars['Int']>;
};

export type IntegrationsoverviewComponent = {
  __typename?: 'IntegrationsoverviewComponent';
  _editable?: Maybe<Scalars['String']>;
  _uid?: Maybe<Scalars['String']>;
  call_to_action?: Maybe<Scalars['BlockScalar']>;
  component?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  seo?: Maybe<Scalars['JsonScalar']>;
  title?: Maybe<Scalars['String']>;
};

export type IntegrationsoverviewFilterQuery = {
  title?: Maybe<FilterQueryOperations>;
  description?: Maybe<FilterQueryOperations>;
};

export type IntegrationsoverviewItem = {
  __typename?: 'IntegrationsoverviewItem';
  alternates?: Maybe<Array<Maybe<Alternate>>>;
  content?: Maybe<IntegrationsoverviewComponent>;
  created_at?: Maybe<Scalars['String']>;
  default_full_slug?: Maybe<Scalars['String']>;
  first_published_at?: Maybe<Scalars['String']>;
  full_slug?: Maybe<Scalars['String']>;
  group_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  is_startpage?: Maybe<Scalars['Boolean']>;
  lang?: Maybe<Scalars['String']>;
  meta_data?: Maybe<Scalars['JsonScalar']>;
  name?: Maybe<Scalars['String']>;
  parent_id?: Maybe<Scalars['Int']>;
  path?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['Int']>;
  published_at?: Maybe<Scalars['String']>;
  release_id?: Maybe<Scalars['Int']>;
  slug?: Maybe<Scalars['String']>;
  sort_by_date?: Maybe<Scalars['String']>;
  tag_list?: Maybe<Array<Maybe<Scalars['String']>>>;
  translated_slugs?: Maybe<Array<Maybe<TranslatedSlug>>>;
  uuid?: Maybe<Scalars['String']>;
};

export type IntegrationsoverviewItems = {
  __typename?: 'IntegrationsoverviewItems';
  items?: Maybe<Array<Maybe<IntegrationsoverviewItem>>>;
  total?: Maybe<Scalars['Int']>;
};


export type LinkEntries = {
  __typename?: 'LinkEntries';
  items: Array<LinkEntry>;
};

export type LinkEntry = {
  __typename?: 'LinkEntry';
  id?: Maybe<Scalars['Int']>;
  isFolder?: Maybe<Scalars['Boolean']>;
  isStartpage?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  parentId?: Maybe<Scalars['Int']>;
  position?: Maybe<Scalars['Int']>;
  published?: Maybe<Scalars['Boolean']>;
  slug?: Maybe<Scalars['String']>;
  uuid?: Maybe<Scalars['String']>;
};

export type NavigationComponent = {
  __typename?: 'NavigationComponent';
  _editable?: Maybe<Scalars['String']>;
  _uid?: Maybe<Scalars['String']>;
  component?: Maybe<Scalars['String']>;
  guides?: Maybe<Scalars['BlockScalar']>;
  link_list?: Maybe<Scalars['BlockScalar']>;
  links?: Maybe<Scalars['BlockScalar']>;
};

export type NavigationItem = {
  __typename?: 'NavigationItem';
  alternates?: Maybe<Array<Maybe<Alternate>>>;
  content?: Maybe<NavigationComponent>;
  created_at?: Maybe<Scalars['String']>;
  default_full_slug?: Maybe<Scalars['String']>;
  first_published_at?: Maybe<Scalars['String']>;
  full_slug?: Maybe<Scalars['String']>;
  group_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  is_startpage?: Maybe<Scalars['Boolean']>;
  lang?: Maybe<Scalars['String']>;
  meta_data?: Maybe<Scalars['JsonScalar']>;
  name?: Maybe<Scalars['String']>;
  parent_id?: Maybe<Scalars['Int']>;
  path?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['Int']>;
  published_at?: Maybe<Scalars['String']>;
  release_id?: Maybe<Scalars['Int']>;
  slug?: Maybe<Scalars['String']>;
  sort_by_date?: Maybe<Scalars['String']>;
  tag_list?: Maybe<Array<Maybe<Scalars['String']>>>;
  translated_slugs?: Maybe<Array<Maybe<TranslatedSlug>>>;
  uuid?: Maybe<Scalars['String']>;
};

export type NavigationItems = {
  __typename?: 'NavigationItems';
  items?: Maybe<Array<Maybe<NavigationItem>>>;
  total?: Maybe<Scalars['Int']>;
};

export type ProductComponent = {
  __typename?: 'ProductComponent';
  _editable?: Maybe<Scalars['String']>;
  _uid?: Maybe<Scalars['String']>;
  component?: Maybe<Scalars['String']>;
  featured_products?: Maybe<Scalars['JsonScalar']>;
  featured_products_title?: Maybe<Scalars['String']>;
};

export type ProductFilterQuery = {
  featured_products_title?: Maybe<FilterQueryOperations>;
};

export type ProductItem = {
  __typename?: 'ProductItem';
  alternates?: Maybe<Array<Maybe<Alternate>>>;
  content?: Maybe<ProductComponent>;
  created_at?: Maybe<Scalars['String']>;
  default_full_slug?: Maybe<Scalars['String']>;
  first_published_at?: Maybe<Scalars['String']>;
  full_slug?: Maybe<Scalars['String']>;
  group_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  is_startpage?: Maybe<Scalars['Boolean']>;
  lang?: Maybe<Scalars['String']>;
  meta_data?: Maybe<Scalars['JsonScalar']>;
  name?: Maybe<Scalars['String']>;
  parent_id?: Maybe<Scalars['Int']>;
  path?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['Int']>;
  published_at?: Maybe<Scalars['String']>;
  release_id?: Maybe<Scalars['Int']>;
  slug?: Maybe<Scalars['String']>;
  sort_by_date?: Maybe<Scalars['String']>;
  tag_list?: Maybe<Array<Maybe<Scalars['String']>>>;
  translated_slugs?: Maybe<Array<Maybe<TranslatedSlug>>>;
  uuid?: Maybe<Scalars['String']>;
};

export type ProductItems = {
  __typename?: 'ProductItems';
  items?: Maybe<Array<Maybe<ProductItem>>>;
  total?: Maybe<Scalars['Int']>;
};

export type ProductlistingComponent = {
  __typename?: 'ProductlistingComponent';
  _editable?: Maybe<Scalars['String']>;
  _uid?: Maybe<Scalars['String']>;
  component?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  seo?: Maybe<Scalars['JsonScalar']>;
  title?: Maybe<Scalars['String']>;
};

export type ProductlistingFilterQuery = {
  title?: Maybe<FilterQueryOperations>;
  description?: Maybe<FilterQueryOperations>;
};

export type ProductlistingItem = {
  __typename?: 'ProductlistingItem';
  alternates?: Maybe<Array<Maybe<Alternate>>>;
  content?: Maybe<ProductlistingComponent>;
  created_at?: Maybe<Scalars['String']>;
  default_full_slug?: Maybe<Scalars['String']>;
  first_published_at?: Maybe<Scalars['String']>;
  full_slug?: Maybe<Scalars['String']>;
  group_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  is_startpage?: Maybe<Scalars['Boolean']>;
  lang?: Maybe<Scalars['String']>;
  meta_data?: Maybe<Scalars['JsonScalar']>;
  name?: Maybe<Scalars['String']>;
  parent_id?: Maybe<Scalars['Int']>;
  path?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['Int']>;
  published_at?: Maybe<Scalars['String']>;
  release_id?: Maybe<Scalars['Int']>;
  slug?: Maybe<Scalars['String']>;
  sort_by_date?: Maybe<Scalars['String']>;
  tag_list?: Maybe<Array<Maybe<Scalars['String']>>>;
  translated_slugs?: Maybe<Array<Maybe<TranslatedSlug>>>;
  uuid?: Maybe<Scalars['String']>;
};

export type ProductlistingItems = {
  __typename?: 'ProductlistingItems';
  items?: Maybe<Array<Maybe<ProductlistingItem>>>;
  total?: Maybe<Scalars['Int']>;
};

export type ProductupdateComponent = {
  __typename?: 'ProductupdateComponent';
  _editable?: Maybe<Scalars['String']>;
  _uid?: Maybe<Scalars['String']>;
  body?: Maybe<Scalars['BlockScalar']>;
  component?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type ProductupdateFilterQuery = {
  title?: Maybe<FilterQueryOperations>;
};

export type ProductupdateItem = {
  __typename?: 'ProductupdateItem';
  alternates?: Maybe<Array<Maybe<Alternate>>>;
  content?: Maybe<ProductupdateComponent>;
  created_at?: Maybe<Scalars['String']>;
  default_full_slug?: Maybe<Scalars['String']>;
  first_published_at?: Maybe<Scalars['String']>;
  full_slug?: Maybe<Scalars['String']>;
  group_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  is_startpage?: Maybe<Scalars['Boolean']>;
  lang?: Maybe<Scalars['String']>;
  meta_data?: Maybe<Scalars['JsonScalar']>;
  name?: Maybe<Scalars['String']>;
  parent_id?: Maybe<Scalars['Int']>;
  path?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['Int']>;
  published_at?: Maybe<Scalars['String']>;
  release_id?: Maybe<Scalars['Int']>;
  slug?: Maybe<Scalars['String']>;
  sort_by_date?: Maybe<Scalars['String']>;
  tag_list?: Maybe<Array<Maybe<Scalars['String']>>>;
  translated_slugs?: Maybe<Array<Maybe<TranslatedSlug>>>;
  uuid?: Maybe<Scalars['String']>;
};

export type ProductupdateItems = {
  __typename?: 'ProductupdateItems';
  items?: Maybe<Array<Maybe<ProductupdateItem>>>;
  total?: Maybe<Scalars['Int']>;
};

export type ProductupdatesComponent = {
  __typename?: 'ProductupdatesComponent';
  _editable?: Maybe<Scalars['String']>;
  _uid?: Maybe<Scalars['String']>;
  body?: Maybe<Scalars['BlockScalar']>;
  component?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type ProductupdatesFilterQuery = {
  title?: Maybe<FilterQueryOperations>;
  date?: Maybe<FilterQueryOperations>;
};

export type ProductupdatesItem = {
  __typename?: 'ProductupdatesItem';
  alternates?: Maybe<Array<Maybe<Alternate>>>;
  content?: Maybe<ProductupdatesComponent>;
  created_at?: Maybe<Scalars['String']>;
  default_full_slug?: Maybe<Scalars['String']>;
  first_published_at?: Maybe<Scalars['String']>;
  full_slug?: Maybe<Scalars['String']>;
  group_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  is_startpage?: Maybe<Scalars['Boolean']>;
  lang?: Maybe<Scalars['String']>;
  meta_data?: Maybe<Scalars['JsonScalar']>;
  name?: Maybe<Scalars['String']>;
  parent_id?: Maybe<Scalars['Int']>;
  path?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['Int']>;
  published_at?: Maybe<Scalars['String']>;
  release_id?: Maybe<Scalars['Int']>;
  slug?: Maybe<Scalars['String']>;
  sort_by_date?: Maybe<Scalars['String']>;
  tag_list?: Maybe<Array<Maybe<Scalars['String']>>>;
  translated_slugs?: Maybe<Array<Maybe<TranslatedSlug>>>;
  uuid?: Maybe<Scalars['String']>;
};

export type ProductupdatesItems = {
  __typename?: 'ProductupdatesItems';
  items?: Maybe<Array<Maybe<ProductupdatesItem>>>;
  total?: Maybe<Scalars['Int']>;
};

export type ProductupdatesoverviewComponent = {
  __typename?: 'ProductupdatesoverviewComponent';
  _editable?: Maybe<Scalars['String']>;
  _uid?: Maybe<Scalars['String']>;
  component?: Maybe<Scalars['String']>;
  seo?: Maybe<Scalars['JsonScalar']>;
  title?: Maybe<Scalars['String']>;
};

export type ProductupdatesoverviewFilterQuery = {
  title?: Maybe<FilterQueryOperations>;
};

export type ProductupdatesoverviewItem = {
  __typename?: 'ProductupdatesoverviewItem';
  alternates?: Maybe<Array<Maybe<Alternate>>>;
  content?: Maybe<ProductupdatesoverviewComponent>;
  created_at?: Maybe<Scalars['String']>;
  default_full_slug?: Maybe<Scalars['String']>;
  first_published_at?: Maybe<Scalars['String']>;
  full_slug?: Maybe<Scalars['String']>;
  group_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  is_startpage?: Maybe<Scalars['Boolean']>;
  lang?: Maybe<Scalars['String']>;
  meta_data?: Maybe<Scalars['JsonScalar']>;
  name?: Maybe<Scalars['String']>;
  parent_id?: Maybe<Scalars['Int']>;
  path?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['Int']>;
  published_at?: Maybe<Scalars['String']>;
  release_id?: Maybe<Scalars['Int']>;
  slug?: Maybe<Scalars['String']>;
  sort_by_date?: Maybe<Scalars['String']>;
  tag_list?: Maybe<Array<Maybe<Scalars['String']>>>;
  translated_slugs?: Maybe<Array<Maybe<TranslatedSlug>>>;
  uuid?: Maybe<Scalars['String']>;
};

export type ProductupdatesoverviewItems = {
  __typename?: 'ProductupdatesoverviewItems';
  items?: Maybe<Array<Maybe<ProductupdatesoverviewItem>>>;
  total?: Maybe<Scalars['Int']>;
};

export type QueryType = {
  __typename?: 'QueryType';
  ContentNode?: Maybe<ContentItem>;
  ContentNodes?: Maybe<ContentItems>;
  DatasourceEntries?: Maybe<DatasourceEntries>;
  Datasources?: Maybe<Datasources>;
  FaqItem?: Maybe<FaqItem>;
  FaqItems?: Maybe<FaqItems>;
  FiltersItem?: Maybe<FiltersItem>;
  FiltersItems?: Maybe<FiltersItems>;
  FooterItem?: Maybe<FooterItem>;
  FooterItems?: Maybe<FooterItems>;
  GuideItem?: Maybe<GuideItem>;
  GuideItems?: Maybe<GuideItems>;
  HomeItem?: Maybe<HomeItem>;
  HomeItems?: Maybe<HomeItems>;
  IntegrationpageItem?: Maybe<IntegrationpageItem>;
  IntegrationpageItems?: Maybe<IntegrationpageItems>;
  IntegrationsoverviewItem?: Maybe<IntegrationsoverviewItem>;
  IntegrationsoverviewItems?: Maybe<IntegrationsoverviewItems>;
  Links?: Maybe<LinkEntries>;
  NavigationItem?: Maybe<NavigationItem>;
  NavigationItems?: Maybe<NavigationItems>;
  ProductItem?: Maybe<ProductItem>;
  ProductItems?: Maybe<ProductItems>;
  ProductlistingItem?: Maybe<ProductlistingItem>;
  ProductlistingItems?: Maybe<ProductlistingItems>;
  ProductupdateItem?: Maybe<ProductupdateItem>;
  ProductupdateItems?: Maybe<ProductupdateItems>;
  ProductupdatesItem?: Maybe<ProductupdatesItem>;
  ProductupdatesItems?: Maybe<ProductupdatesItems>;
  ProductupdatesoverviewItem?: Maybe<ProductupdatesoverviewItem>;
  ProductupdatesoverviewItems?: Maybe<ProductupdatesoverviewItems>;
  RateLimit?: Maybe<RateLimit>;
  Space?: Maybe<Space>;
  Tags?: Maybe<Tags>;
  TermsandconditionsItem?: Maybe<TermsandconditionsItem>;
  TermsandconditionsItems?: Maybe<TermsandconditionsItems>;
};


export type QueryTypeContentNodeArgs = {
  id: Scalars['ID'];
  find_by?: Maybe<Scalars['String']>;
  from_release?: Maybe<Scalars['Int']>;
  resolve_links?: Maybe<Scalars['String']>;
  resolve_relations?: Maybe<Scalars['String']>;
  language?: Maybe<Scalars['String']>;
};


export type QueryTypeContentNodesArgs = {
  first_published_at_gt?: Maybe<Scalars['String']>;
  first_published_at_lt?: Maybe<Scalars['String']>;
  published_at_gt?: Maybe<Scalars['String']>;
  published_at_lt?: Maybe<Scalars['String']>;
  starts_with?: Maybe<Scalars['String']>;
  by_slugs?: Maybe<Scalars['String']>;
  excluding_slugs?: Maybe<Scalars['String']>;
  fallback_lang?: Maybe<Scalars['String']>;
  by_uuids?: Maybe<Scalars['String']>;
  by_uuids_ordered?: Maybe<Scalars['String']>;
  excluding_ids?: Maybe<Scalars['String']>;
  excluding_fields?: Maybe<Scalars['String']>;
  resolve_links?: Maybe<Scalars['String']>;
  resolve_relations?: Maybe<Scalars['String']>;
  from_release?: Maybe<Scalars['String']>;
  sort_by?: Maybe<Scalars['String']>;
  search_term?: Maybe<Scalars['String']>;
  is_startpage?: Maybe<Scalars['String']>;
  language?: Maybe<Scalars['String']>;
  with_tag?: Maybe<Scalars['String']>;
  page?: Maybe<Scalars['Int']>;
  per_page?: Maybe<Scalars['Int']>;
  filter_query?: Maybe<Scalars['JsonScalar']>;
};


export type QueryTypeDatasourceEntriesArgs = {
  datasource?: Maybe<Scalars['String']>;
  dimension?: Maybe<Scalars['String']>;
  page?: Maybe<Scalars['Int']>;
  per_page?: Maybe<Scalars['Int']>;
};


export type QueryTypeDatasourcesArgs = {
  search?: Maybe<Scalars['String']>;
  by_ids?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type QueryTypeFaqItemArgs = {
  id: Scalars['ID'];
  find_by?: Maybe<Scalars['String']>;
  from_release?: Maybe<Scalars['Int']>;
  resolve_links?: Maybe<Scalars['String']>;
  resolve_relations?: Maybe<Scalars['String']>;
  language?: Maybe<Scalars['String']>;
};


export type QueryTypeFaqItemsArgs = {
  first_published_at_gt?: Maybe<Scalars['String']>;
  first_published_at_lt?: Maybe<Scalars['String']>;
  published_at_gt?: Maybe<Scalars['String']>;
  published_at_lt?: Maybe<Scalars['String']>;
  starts_with?: Maybe<Scalars['String']>;
  by_slugs?: Maybe<Scalars['String']>;
  excluding_slugs?: Maybe<Scalars['String']>;
  fallback_lang?: Maybe<Scalars['String']>;
  by_uuids?: Maybe<Scalars['String']>;
  by_uuids_ordered?: Maybe<Scalars['String']>;
  excluding_ids?: Maybe<Scalars['String']>;
  excluding_fields?: Maybe<Scalars['String']>;
  resolve_links?: Maybe<Scalars['String']>;
  resolve_relations?: Maybe<Scalars['String']>;
  from_release?: Maybe<Scalars['String']>;
  sort_by?: Maybe<Scalars['String']>;
  search_term?: Maybe<Scalars['String']>;
  is_startpage?: Maybe<Scalars['String']>;
  language?: Maybe<Scalars['String']>;
  with_tag?: Maybe<Scalars['String']>;
  page?: Maybe<Scalars['Int']>;
  per_page?: Maybe<Scalars['Int']>;
  filter_query?: Maybe<Scalars['JsonScalar']>;
  filter_query_v2?: Maybe<FaqFilterQuery>;
};


export type QueryTypeFiltersItemArgs = {
  id: Scalars['ID'];
  find_by?: Maybe<Scalars['String']>;
  from_release?: Maybe<Scalars['Int']>;
  resolve_links?: Maybe<Scalars['String']>;
  resolve_relations?: Maybe<Scalars['String']>;
  language?: Maybe<Scalars['String']>;
};


export type QueryTypeFiltersItemsArgs = {
  first_published_at_gt?: Maybe<Scalars['String']>;
  first_published_at_lt?: Maybe<Scalars['String']>;
  published_at_gt?: Maybe<Scalars['String']>;
  published_at_lt?: Maybe<Scalars['String']>;
  starts_with?: Maybe<Scalars['String']>;
  by_slugs?: Maybe<Scalars['String']>;
  excluding_slugs?: Maybe<Scalars['String']>;
  fallback_lang?: Maybe<Scalars['String']>;
  by_uuids?: Maybe<Scalars['String']>;
  by_uuids_ordered?: Maybe<Scalars['String']>;
  excluding_ids?: Maybe<Scalars['String']>;
  excluding_fields?: Maybe<Scalars['String']>;
  resolve_links?: Maybe<Scalars['String']>;
  resolve_relations?: Maybe<Scalars['String']>;
  from_release?: Maybe<Scalars['String']>;
  sort_by?: Maybe<Scalars['String']>;
  search_term?: Maybe<Scalars['String']>;
  is_startpage?: Maybe<Scalars['String']>;
  language?: Maybe<Scalars['String']>;
  with_tag?: Maybe<Scalars['String']>;
  page?: Maybe<Scalars['Int']>;
  per_page?: Maybe<Scalars['Int']>;
  filter_query?: Maybe<Scalars['JsonScalar']>;
};


export type QueryTypeFooterItemArgs = {
  id: Scalars['ID'];
  find_by?: Maybe<Scalars['String']>;
  from_release?: Maybe<Scalars['Int']>;
  resolve_links?: Maybe<Scalars['String']>;
  resolve_relations?: Maybe<Scalars['String']>;
  language?: Maybe<Scalars['String']>;
};


export type QueryTypeFooterItemsArgs = {
  first_published_at_gt?: Maybe<Scalars['String']>;
  first_published_at_lt?: Maybe<Scalars['String']>;
  published_at_gt?: Maybe<Scalars['String']>;
  published_at_lt?: Maybe<Scalars['String']>;
  starts_with?: Maybe<Scalars['String']>;
  by_slugs?: Maybe<Scalars['String']>;
  excluding_slugs?: Maybe<Scalars['String']>;
  fallback_lang?: Maybe<Scalars['String']>;
  by_uuids?: Maybe<Scalars['String']>;
  by_uuids_ordered?: Maybe<Scalars['String']>;
  excluding_ids?: Maybe<Scalars['String']>;
  excluding_fields?: Maybe<Scalars['String']>;
  resolve_links?: Maybe<Scalars['String']>;
  resolve_relations?: Maybe<Scalars['String']>;
  from_release?: Maybe<Scalars['String']>;
  sort_by?: Maybe<Scalars['String']>;
  search_term?: Maybe<Scalars['String']>;
  is_startpage?: Maybe<Scalars['String']>;
  language?: Maybe<Scalars['String']>;
  with_tag?: Maybe<Scalars['String']>;
  page?: Maybe<Scalars['Int']>;
  per_page?: Maybe<Scalars['Int']>;
  filter_query?: Maybe<Scalars['JsonScalar']>;
  filter_query_v2?: Maybe<FooterFilterQuery>;
};


export type QueryTypeGuideItemArgs = {
  id: Scalars['ID'];
  find_by?: Maybe<Scalars['String']>;
  from_release?: Maybe<Scalars['Int']>;
  resolve_links?: Maybe<Scalars['String']>;
  resolve_relations?: Maybe<Scalars['String']>;
  language?: Maybe<Scalars['String']>;
};


export type QueryTypeGuideItemsArgs = {
  first_published_at_gt?: Maybe<Scalars['String']>;
  first_published_at_lt?: Maybe<Scalars['String']>;
  published_at_gt?: Maybe<Scalars['String']>;
  published_at_lt?: Maybe<Scalars['String']>;
  starts_with?: Maybe<Scalars['String']>;
  by_slugs?: Maybe<Scalars['String']>;
  excluding_slugs?: Maybe<Scalars['String']>;
  fallback_lang?: Maybe<Scalars['String']>;
  by_uuids?: Maybe<Scalars['String']>;
  by_uuids_ordered?: Maybe<Scalars['String']>;
  excluding_ids?: Maybe<Scalars['String']>;
  excluding_fields?: Maybe<Scalars['String']>;
  resolve_links?: Maybe<Scalars['String']>;
  resolve_relations?: Maybe<Scalars['String']>;
  from_release?: Maybe<Scalars['String']>;
  sort_by?: Maybe<Scalars['String']>;
  search_term?: Maybe<Scalars['String']>;
  is_startpage?: Maybe<Scalars['String']>;
  language?: Maybe<Scalars['String']>;
  with_tag?: Maybe<Scalars['String']>;
  page?: Maybe<Scalars['Int']>;
  per_page?: Maybe<Scalars['Int']>;
  filter_query?: Maybe<Scalars['JsonScalar']>;
};


export type QueryTypeHomeItemArgs = {
  id: Scalars['ID'];
  find_by?: Maybe<Scalars['String']>;
  from_release?: Maybe<Scalars['Int']>;
  resolve_links?: Maybe<Scalars['String']>;
  resolve_relations?: Maybe<Scalars['String']>;
  language?: Maybe<Scalars['String']>;
};


export type QueryTypeHomeItemsArgs = {
  first_published_at_gt?: Maybe<Scalars['String']>;
  first_published_at_lt?: Maybe<Scalars['String']>;
  published_at_gt?: Maybe<Scalars['String']>;
  published_at_lt?: Maybe<Scalars['String']>;
  starts_with?: Maybe<Scalars['String']>;
  by_slugs?: Maybe<Scalars['String']>;
  excluding_slugs?: Maybe<Scalars['String']>;
  fallback_lang?: Maybe<Scalars['String']>;
  by_uuids?: Maybe<Scalars['String']>;
  by_uuids_ordered?: Maybe<Scalars['String']>;
  excluding_ids?: Maybe<Scalars['String']>;
  excluding_fields?: Maybe<Scalars['String']>;
  resolve_links?: Maybe<Scalars['String']>;
  resolve_relations?: Maybe<Scalars['String']>;
  from_release?: Maybe<Scalars['String']>;
  sort_by?: Maybe<Scalars['String']>;
  search_term?: Maybe<Scalars['String']>;
  is_startpage?: Maybe<Scalars['String']>;
  language?: Maybe<Scalars['String']>;
  with_tag?: Maybe<Scalars['String']>;
  page?: Maybe<Scalars['Int']>;
  per_page?: Maybe<Scalars['Int']>;
  filter_query?: Maybe<Scalars['JsonScalar']>;
  filter_query_v2?: Maybe<HomeFilterQuery>;
};


export type QueryTypeIntegrationpageItemArgs = {
  id: Scalars['ID'];
  find_by?: Maybe<Scalars['String']>;
  from_release?: Maybe<Scalars['Int']>;
  resolve_links?: Maybe<Scalars['String']>;
  resolve_relations?: Maybe<Scalars['String']>;
  language?: Maybe<Scalars['String']>;
};


export type QueryTypeIntegrationpageItemsArgs = {
  first_published_at_gt?: Maybe<Scalars['String']>;
  first_published_at_lt?: Maybe<Scalars['String']>;
  published_at_gt?: Maybe<Scalars['String']>;
  published_at_lt?: Maybe<Scalars['String']>;
  starts_with?: Maybe<Scalars['String']>;
  by_slugs?: Maybe<Scalars['String']>;
  excluding_slugs?: Maybe<Scalars['String']>;
  fallback_lang?: Maybe<Scalars['String']>;
  by_uuids?: Maybe<Scalars['String']>;
  by_uuids_ordered?: Maybe<Scalars['String']>;
  excluding_ids?: Maybe<Scalars['String']>;
  excluding_fields?: Maybe<Scalars['String']>;
  resolve_links?: Maybe<Scalars['String']>;
  resolve_relations?: Maybe<Scalars['String']>;
  from_release?: Maybe<Scalars['String']>;
  sort_by?: Maybe<Scalars['String']>;
  search_term?: Maybe<Scalars['String']>;
  is_startpage?: Maybe<Scalars['String']>;
  language?: Maybe<Scalars['String']>;
  with_tag?: Maybe<Scalars['String']>;
  page?: Maybe<Scalars['Int']>;
  per_page?: Maybe<Scalars['Int']>;
  filter_query?: Maybe<Scalars['JsonScalar']>;
  filter_query_v2?: Maybe<IntegrationpageFilterQuery>;
};


export type QueryTypeIntegrationsoverviewItemArgs = {
  id: Scalars['ID'];
  find_by?: Maybe<Scalars['String']>;
  from_release?: Maybe<Scalars['Int']>;
  resolve_links?: Maybe<Scalars['String']>;
  resolve_relations?: Maybe<Scalars['String']>;
  language?: Maybe<Scalars['String']>;
};


export type QueryTypeIntegrationsoverviewItemsArgs = {
  first_published_at_gt?: Maybe<Scalars['String']>;
  first_published_at_lt?: Maybe<Scalars['String']>;
  published_at_gt?: Maybe<Scalars['String']>;
  published_at_lt?: Maybe<Scalars['String']>;
  starts_with?: Maybe<Scalars['String']>;
  by_slugs?: Maybe<Scalars['String']>;
  excluding_slugs?: Maybe<Scalars['String']>;
  fallback_lang?: Maybe<Scalars['String']>;
  by_uuids?: Maybe<Scalars['String']>;
  by_uuids_ordered?: Maybe<Scalars['String']>;
  excluding_ids?: Maybe<Scalars['String']>;
  excluding_fields?: Maybe<Scalars['String']>;
  resolve_links?: Maybe<Scalars['String']>;
  resolve_relations?: Maybe<Scalars['String']>;
  from_release?: Maybe<Scalars['String']>;
  sort_by?: Maybe<Scalars['String']>;
  search_term?: Maybe<Scalars['String']>;
  is_startpage?: Maybe<Scalars['String']>;
  language?: Maybe<Scalars['String']>;
  with_tag?: Maybe<Scalars['String']>;
  page?: Maybe<Scalars['Int']>;
  per_page?: Maybe<Scalars['Int']>;
  filter_query?: Maybe<Scalars['JsonScalar']>;
  filter_query_v2?: Maybe<IntegrationsoverviewFilterQuery>;
};


export type QueryTypeLinksArgs = {
  starts_with?: Maybe<Scalars['String']>;
  paginated?: Maybe<Scalars['Boolean']>;
};


export type QueryTypeNavigationItemArgs = {
  id: Scalars['ID'];
  find_by?: Maybe<Scalars['String']>;
  from_release?: Maybe<Scalars['Int']>;
  resolve_links?: Maybe<Scalars['String']>;
  resolve_relations?: Maybe<Scalars['String']>;
  language?: Maybe<Scalars['String']>;
};


export type QueryTypeNavigationItemsArgs = {
  first_published_at_gt?: Maybe<Scalars['String']>;
  first_published_at_lt?: Maybe<Scalars['String']>;
  published_at_gt?: Maybe<Scalars['String']>;
  published_at_lt?: Maybe<Scalars['String']>;
  starts_with?: Maybe<Scalars['String']>;
  by_slugs?: Maybe<Scalars['String']>;
  excluding_slugs?: Maybe<Scalars['String']>;
  fallback_lang?: Maybe<Scalars['String']>;
  by_uuids?: Maybe<Scalars['String']>;
  by_uuids_ordered?: Maybe<Scalars['String']>;
  excluding_ids?: Maybe<Scalars['String']>;
  excluding_fields?: Maybe<Scalars['String']>;
  resolve_links?: Maybe<Scalars['String']>;
  resolve_relations?: Maybe<Scalars['String']>;
  from_release?: Maybe<Scalars['String']>;
  sort_by?: Maybe<Scalars['String']>;
  search_term?: Maybe<Scalars['String']>;
  is_startpage?: Maybe<Scalars['String']>;
  language?: Maybe<Scalars['String']>;
  with_tag?: Maybe<Scalars['String']>;
  page?: Maybe<Scalars['Int']>;
  per_page?: Maybe<Scalars['Int']>;
  filter_query?: Maybe<Scalars['JsonScalar']>;
};


export type QueryTypeProductItemArgs = {
  id: Scalars['ID'];
  find_by?: Maybe<Scalars['String']>;
  from_release?: Maybe<Scalars['Int']>;
  resolve_links?: Maybe<Scalars['String']>;
  resolve_relations?: Maybe<Scalars['String']>;
  language?: Maybe<Scalars['String']>;
};


export type QueryTypeProductItemsArgs = {
  first_published_at_gt?: Maybe<Scalars['String']>;
  first_published_at_lt?: Maybe<Scalars['String']>;
  published_at_gt?: Maybe<Scalars['String']>;
  published_at_lt?: Maybe<Scalars['String']>;
  starts_with?: Maybe<Scalars['String']>;
  by_slugs?: Maybe<Scalars['String']>;
  excluding_slugs?: Maybe<Scalars['String']>;
  fallback_lang?: Maybe<Scalars['String']>;
  by_uuids?: Maybe<Scalars['String']>;
  by_uuids_ordered?: Maybe<Scalars['String']>;
  excluding_ids?: Maybe<Scalars['String']>;
  excluding_fields?: Maybe<Scalars['String']>;
  resolve_links?: Maybe<Scalars['String']>;
  resolve_relations?: Maybe<Scalars['String']>;
  from_release?: Maybe<Scalars['String']>;
  sort_by?: Maybe<Scalars['String']>;
  search_term?: Maybe<Scalars['String']>;
  is_startpage?: Maybe<Scalars['String']>;
  language?: Maybe<Scalars['String']>;
  with_tag?: Maybe<Scalars['String']>;
  page?: Maybe<Scalars['Int']>;
  per_page?: Maybe<Scalars['Int']>;
  filter_query?: Maybe<Scalars['JsonScalar']>;
  filter_query_v2?: Maybe<ProductFilterQuery>;
};


export type QueryTypeProductlistingItemArgs = {
  id: Scalars['ID'];
  find_by?: Maybe<Scalars['String']>;
  from_release?: Maybe<Scalars['Int']>;
  resolve_links?: Maybe<Scalars['String']>;
  resolve_relations?: Maybe<Scalars['String']>;
  language?: Maybe<Scalars['String']>;
};


export type QueryTypeProductlistingItemsArgs = {
  first_published_at_gt?: Maybe<Scalars['String']>;
  first_published_at_lt?: Maybe<Scalars['String']>;
  published_at_gt?: Maybe<Scalars['String']>;
  published_at_lt?: Maybe<Scalars['String']>;
  starts_with?: Maybe<Scalars['String']>;
  by_slugs?: Maybe<Scalars['String']>;
  excluding_slugs?: Maybe<Scalars['String']>;
  fallback_lang?: Maybe<Scalars['String']>;
  by_uuids?: Maybe<Scalars['String']>;
  by_uuids_ordered?: Maybe<Scalars['String']>;
  excluding_ids?: Maybe<Scalars['String']>;
  excluding_fields?: Maybe<Scalars['String']>;
  resolve_links?: Maybe<Scalars['String']>;
  resolve_relations?: Maybe<Scalars['String']>;
  from_release?: Maybe<Scalars['String']>;
  sort_by?: Maybe<Scalars['String']>;
  search_term?: Maybe<Scalars['String']>;
  is_startpage?: Maybe<Scalars['String']>;
  language?: Maybe<Scalars['String']>;
  with_tag?: Maybe<Scalars['String']>;
  page?: Maybe<Scalars['Int']>;
  per_page?: Maybe<Scalars['Int']>;
  filter_query?: Maybe<Scalars['JsonScalar']>;
  filter_query_v2?: Maybe<ProductlistingFilterQuery>;
};


export type QueryTypeProductupdateItemArgs = {
  id: Scalars['ID'];
  find_by?: Maybe<Scalars['String']>;
  from_release?: Maybe<Scalars['Int']>;
  resolve_links?: Maybe<Scalars['String']>;
  resolve_relations?: Maybe<Scalars['String']>;
  language?: Maybe<Scalars['String']>;
};


export type QueryTypeProductupdateItemsArgs = {
  first_published_at_gt?: Maybe<Scalars['String']>;
  first_published_at_lt?: Maybe<Scalars['String']>;
  published_at_gt?: Maybe<Scalars['String']>;
  published_at_lt?: Maybe<Scalars['String']>;
  starts_with?: Maybe<Scalars['String']>;
  by_slugs?: Maybe<Scalars['String']>;
  excluding_slugs?: Maybe<Scalars['String']>;
  fallback_lang?: Maybe<Scalars['String']>;
  by_uuids?: Maybe<Scalars['String']>;
  by_uuids_ordered?: Maybe<Scalars['String']>;
  excluding_ids?: Maybe<Scalars['String']>;
  excluding_fields?: Maybe<Scalars['String']>;
  resolve_links?: Maybe<Scalars['String']>;
  resolve_relations?: Maybe<Scalars['String']>;
  from_release?: Maybe<Scalars['String']>;
  sort_by?: Maybe<Scalars['String']>;
  search_term?: Maybe<Scalars['String']>;
  is_startpage?: Maybe<Scalars['String']>;
  language?: Maybe<Scalars['String']>;
  with_tag?: Maybe<Scalars['String']>;
  page?: Maybe<Scalars['Int']>;
  per_page?: Maybe<Scalars['Int']>;
  filter_query?: Maybe<Scalars['JsonScalar']>;
  filter_query_v2?: Maybe<ProductupdateFilterQuery>;
};


export type QueryTypeProductupdatesItemArgs = {
  id: Scalars['ID'];
  find_by?: Maybe<Scalars['String']>;
  from_release?: Maybe<Scalars['Int']>;
  resolve_links?: Maybe<Scalars['String']>;
  resolve_relations?: Maybe<Scalars['String']>;
  language?: Maybe<Scalars['String']>;
};


export type QueryTypeProductupdatesItemsArgs = {
  first_published_at_gt?: Maybe<Scalars['String']>;
  first_published_at_lt?: Maybe<Scalars['String']>;
  published_at_gt?: Maybe<Scalars['String']>;
  published_at_lt?: Maybe<Scalars['String']>;
  starts_with?: Maybe<Scalars['String']>;
  by_slugs?: Maybe<Scalars['String']>;
  excluding_slugs?: Maybe<Scalars['String']>;
  fallback_lang?: Maybe<Scalars['String']>;
  by_uuids?: Maybe<Scalars['String']>;
  by_uuids_ordered?: Maybe<Scalars['String']>;
  excluding_ids?: Maybe<Scalars['String']>;
  excluding_fields?: Maybe<Scalars['String']>;
  resolve_links?: Maybe<Scalars['String']>;
  resolve_relations?: Maybe<Scalars['String']>;
  from_release?: Maybe<Scalars['String']>;
  sort_by?: Maybe<Scalars['String']>;
  search_term?: Maybe<Scalars['String']>;
  is_startpage?: Maybe<Scalars['String']>;
  language?: Maybe<Scalars['String']>;
  with_tag?: Maybe<Scalars['String']>;
  page?: Maybe<Scalars['Int']>;
  per_page?: Maybe<Scalars['Int']>;
  filter_query?: Maybe<Scalars['JsonScalar']>;
  filter_query_v2?: Maybe<ProductupdatesFilterQuery>;
};


export type QueryTypeProductupdatesoverviewItemArgs = {
  id: Scalars['ID'];
  find_by?: Maybe<Scalars['String']>;
  from_release?: Maybe<Scalars['Int']>;
  resolve_links?: Maybe<Scalars['String']>;
  resolve_relations?: Maybe<Scalars['String']>;
  language?: Maybe<Scalars['String']>;
};


export type QueryTypeProductupdatesoverviewItemsArgs = {
  first_published_at_gt?: Maybe<Scalars['String']>;
  first_published_at_lt?: Maybe<Scalars['String']>;
  published_at_gt?: Maybe<Scalars['String']>;
  published_at_lt?: Maybe<Scalars['String']>;
  starts_with?: Maybe<Scalars['String']>;
  by_slugs?: Maybe<Scalars['String']>;
  excluding_slugs?: Maybe<Scalars['String']>;
  fallback_lang?: Maybe<Scalars['String']>;
  by_uuids?: Maybe<Scalars['String']>;
  by_uuids_ordered?: Maybe<Scalars['String']>;
  excluding_ids?: Maybe<Scalars['String']>;
  excluding_fields?: Maybe<Scalars['String']>;
  resolve_links?: Maybe<Scalars['String']>;
  resolve_relations?: Maybe<Scalars['String']>;
  from_release?: Maybe<Scalars['String']>;
  sort_by?: Maybe<Scalars['String']>;
  search_term?: Maybe<Scalars['String']>;
  is_startpage?: Maybe<Scalars['String']>;
  language?: Maybe<Scalars['String']>;
  with_tag?: Maybe<Scalars['String']>;
  page?: Maybe<Scalars['Int']>;
  per_page?: Maybe<Scalars['Int']>;
  filter_query?: Maybe<Scalars['JsonScalar']>;
  filter_query_v2?: Maybe<ProductupdatesoverviewFilterQuery>;
};


export type QueryTypeTagsArgs = {
  starts_with?: Maybe<Scalars['String']>;
};


export type QueryTypeTermsandconditionsItemArgs = {
  id: Scalars['ID'];
  find_by?: Maybe<Scalars['String']>;
  from_release?: Maybe<Scalars['Int']>;
  resolve_links?: Maybe<Scalars['String']>;
  resolve_relations?: Maybe<Scalars['String']>;
  language?: Maybe<Scalars['String']>;
};


export type QueryTypeTermsandconditionsItemsArgs = {
  first_published_at_gt?: Maybe<Scalars['String']>;
  first_published_at_lt?: Maybe<Scalars['String']>;
  published_at_gt?: Maybe<Scalars['String']>;
  published_at_lt?: Maybe<Scalars['String']>;
  starts_with?: Maybe<Scalars['String']>;
  by_slugs?: Maybe<Scalars['String']>;
  excluding_slugs?: Maybe<Scalars['String']>;
  fallback_lang?: Maybe<Scalars['String']>;
  by_uuids?: Maybe<Scalars['String']>;
  by_uuids_ordered?: Maybe<Scalars['String']>;
  excluding_ids?: Maybe<Scalars['String']>;
  excluding_fields?: Maybe<Scalars['String']>;
  resolve_links?: Maybe<Scalars['String']>;
  resolve_relations?: Maybe<Scalars['String']>;
  from_release?: Maybe<Scalars['String']>;
  sort_by?: Maybe<Scalars['String']>;
  search_term?: Maybe<Scalars['String']>;
  is_startpage?: Maybe<Scalars['String']>;
  language?: Maybe<Scalars['String']>;
  with_tag?: Maybe<Scalars['String']>;
  page?: Maybe<Scalars['Int']>;
  per_page?: Maybe<Scalars['Int']>;
  filter_query?: Maybe<Scalars['JsonScalar']>;
};

export type RateLimit = {
  __typename?: 'RateLimit';
  maxCost: Scalars['Int'];
};

export type Space = {
  __typename?: 'Space';
  domain: Scalars['String'];
  id: Scalars['Int'];
  languageCodes: Array<Maybe<Scalars['String']>>;
  name: Scalars['String'];
  version: Scalars['Int'];
};

export type Tag = {
  __typename?: 'Tag';
  name: Scalars['String'];
  taggingsCount: Scalars['Int'];
};

export type Tags = {
  __typename?: 'Tags';
  items: Array<Tag>;
};

export type TermsandconditionsComponent = {
  __typename?: 'TermsandconditionsComponent';
  _editable?: Maybe<Scalars['String']>;
  _uid?: Maybe<Scalars['String']>;
  component?: Maybe<Scalars['String']>;
  terms_and_conditions?: Maybe<Scalars['JsonScalar']>;
};

export type TermsandconditionsItem = {
  __typename?: 'TermsandconditionsItem';
  alternates?: Maybe<Array<Maybe<Alternate>>>;
  content?: Maybe<TermsandconditionsComponent>;
  created_at?: Maybe<Scalars['String']>;
  default_full_slug?: Maybe<Scalars['String']>;
  first_published_at?: Maybe<Scalars['String']>;
  full_slug?: Maybe<Scalars['String']>;
  group_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  is_startpage?: Maybe<Scalars['Boolean']>;
  lang?: Maybe<Scalars['String']>;
  meta_data?: Maybe<Scalars['JsonScalar']>;
  name?: Maybe<Scalars['String']>;
  parent_id?: Maybe<Scalars['Int']>;
  path?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['Int']>;
  published_at?: Maybe<Scalars['String']>;
  release_id?: Maybe<Scalars['Int']>;
  slug?: Maybe<Scalars['String']>;
  sort_by_date?: Maybe<Scalars['String']>;
  tag_list?: Maybe<Array<Maybe<Scalars['String']>>>;
  translated_slugs?: Maybe<Array<Maybe<TranslatedSlug>>>;
  uuid?: Maybe<Scalars['String']>;
};

export type TermsandconditionsItems = {
  __typename?: 'TermsandconditionsItems';
  items?: Maybe<Array<Maybe<TermsandconditionsItem>>>;
  total?: Maybe<Scalars['Int']>;
};

export type TranslatedSlug = {
  __typename?: 'TranslatedSlug';
  lang: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  path?: Maybe<Scalars['String']>;
};

export type FaqQueryVariables = Exact<{
  slug: Scalars['ID'];
}>;


export type FaqQuery = (
  { __typename?: 'QueryType' }
  & { FaqItem?: Maybe<(
    { __typename?: 'FaqItem' }
    & Pick<FaqItem, 'uuid'>
    & { content?: Maybe<(
      { __typename?: 'FaqComponent' }
      & Pick<FaqComponent, 'title' | 'component' | 'questions'>
    )> }
  )> }
);

export type FiltersItemsQueryVariables = Exact<{ [key: string]: never; }>;


export type FiltersItemsQuery = (
  { __typename?: 'QueryType' }
  & { FiltersItems?: Maybe<(
    { __typename?: 'FiltersItems' }
    & { items?: Maybe<Array<Maybe<(
      { __typename?: 'FiltersItem' }
      & Pick<FiltersItem, 'uuid'>
      & { content?: Maybe<(
        { __typename?: 'FiltersComponent' }
        & Pick<FiltersComponent, 'tag_groups' | '_editable' | '_uid'>
      )> }
    )>>> }
  )> }
);

export type FooterQueryVariables = Exact<{
  slug: Scalars['ID'];
}>;


export type FooterQuery = (
  { __typename?: 'QueryType' }
  & { FooterItem?: Maybe<(
    { __typename?: 'FooterItem' }
    & Pick<FooterItem, 'published_at' | 'uuid'>
    & { content?: Maybe<(
      { __typename?: 'FooterComponent' }
      & Pick<FooterComponent, 'icon_links' | 'title' | 'link_list' | 'signup_cta' | 'input_placeholder' | 'signup_description' | '_editable'>
    )> }
  )> }
);

export type GuideQueryVariables = Exact<{
  slug: Scalars['ID'];
}>;


export type GuideQuery = (
  { __typename?: 'QueryType' }
  & { GuideItem?: Maybe<(
    { __typename?: 'GuideItem' }
    & Pick<GuideItem, 'uuid'>
    & { content?: Maybe<(
      { __typename?: 'GuideComponent' }
      & Pick<GuideComponent, 'body' | 'seo' | '_editable'>
    )> }
  )> }
);

export type HomeQueryVariables = Exact<{
  slug: Scalars['ID'];
}>;


export type HomeQuery = (
  { __typename?: 'QueryType' }
  & { HomeItem?: Maybe<(
    { __typename?: 'HomeItem' }
    & Pick<HomeItem, 'uuid'>
    & { content?: Maybe<(
      { __typename?: 'HomeComponent' }
      & Pick<HomeComponent, 'title' | 'description' | 'cards' | 'seo' | '_editable'>
    )> }
  )> }
);

export type IntegrationQueryVariables = Exact<{
  slug: Scalars['ID'];
}>;


export type IntegrationQuery = (
  { __typename?: 'QueryType' }
  & { IntegrationpageItem?: Maybe<(
    { __typename?: 'IntegrationpageItem' }
    & Pick<IntegrationpageItem, 'slug' | 'uuid'>
    & { content?: Maybe<(
      { __typename?: 'IntegrationpageComponent' }
      & Pick<IntegrationpageComponent, 'title' | 'description' | '_editable' | '_uid'>
      & { bg?: Maybe<(
        { __typename?: 'Asset' }
        & Pick<Asset, 'alt' | 'filename' | 'focus'>
      )> }
    )> }
  )> }
);

export type IntegrationsQueryVariables = Exact<{ [key: string]: never; }>;


export type IntegrationsQuery = (
  { __typename?: 'QueryType' }
  & { IntegrationpageItems?: Maybe<(
    { __typename?: 'IntegrationpageItems' }
    & { items?: Maybe<Array<Maybe<(
      { __typename?: 'IntegrationpageItem' }
      & Pick<IntegrationpageItem, 'slug' | 'full_slug'>
      & { content?: Maybe<(
        { __typename?: 'IntegrationpageComponent' }
        & Pick<IntegrationpageComponent, 'seo' | 'title' | 'shortDescription'>
        & { bg?: Maybe<(
          { __typename?: 'Asset' }
          & Pick<Asset, 'alt' | 'filename' | 'focus'>
        )> }
      )> }
    )>>> }
  )> }
);

export type IntegrationsOverviewQueryVariables = Exact<{
  slug: Scalars['ID'];
}>;


export type IntegrationsOverviewQuery = (
  { __typename?: 'QueryType' }
  & { IntegrationsoverviewItem?: Maybe<(
    { __typename?: 'IntegrationsoverviewItem' }
    & Pick<IntegrationsoverviewItem, 'id' | 'name' | 'slug' | 'uuid'>
    & { content?: Maybe<(
      { __typename?: 'IntegrationsoverviewComponent' }
      & Pick<IntegrationsoverviewComponent, 'title' | 'description' | 'call_to_action' | 'seo' | '_editable' | '_uid'>
    )> }
  )> }
);

export type NavigationQueryVariables = Exact<{
  slug: Scalars['ID'];
}>;


export type NavigationQuery = (
  { __typename?: 'QueryType' }
  & { NavigationItem?: Maybe<(
    { __typename?: 'NavigationItem' }
    & Pick<NavigationItem, 'published_at' | 'uuid'>
    & { content?: Maybe<(
      { __typename?: 'NavigationComponent' }
      & Pick<NavigationComponent, 'link_list' | 'guides' | 'links' | '_editable'>
    )> }
  )> }
);

export type ProductByIdQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type ProductByIdQuery = (
  { __typename?: 'QueryType' }
  & { ProductItem?: Maybe<(
    { __typename?: 'ProductItem' }
    & Pick<ProductItem, 'uuid' | 'slug'>
    & { content?: Maybe<(
      { __typename?: 'ProductComponent' }
      & Pick<ProductComponent, '_editable' | 'featured_products' | 'featured_products_title'>
    )> }
  )> }
);

export type ProductUpdatesQueryVariables = Exact<{
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
}>;


export type ProductUpdatesQuery = (
  { __typename?: 'QueryType' }
  & { ProductupdateItems?: Maybe<(
    { __typename?: 'ProductupdateItems' }
    & { items?: Maybe<Array<Maybe<(
      { __typename?: 'ProductupdateItem' }
      & Pick<ProductupdateItem, 'uuid'>
      & { content?: Maybe<(
        { __typename?: 'ProductupdateComponent' }
        & Pick<ProductupdateComponent, 'title' | 'date' | 'body'>
      )> }
    )>>> }
  )> }
);

export type ProductUpdatesOverviewQueryVariables = Exact<{
  slug: Scalars['ID'];
}>;


export type ProductUpdatesOverviewQuery = (
  { __typename?: 'QueryType' }
  & { ProductupdatesoverviewItem?: Maybe<(
    { __typename?: 'ProductupdatesoverviewItem' }
    & { content?: Maybe<(
      { __typename?: 'ProductupdatesoverviewComponent' }
      & Pick<ProductupdatesoverviewComponent, 'title' | 'seo' | '_uid'>
    )> }
  )> }
);

export type ProductsQueryVariables = Exact<{
  slug: Scalars['ID'];
}>;


export type ProductsQuery = (
  { __typename?: 'QueryType' }
  & { ProductlistingItem?: Maybe<(
    { __typename?: 'ProductlistingItem' }
    & Pick<ProductlistingItem, 'id' | 'name' | 'slug' | 'uuid'>
    & { content?: Maybe<(
      { __typename?: 'ProductlistingComponent' }
      & Pick<ProductlistingComponent, 'title' | 'description' | 'seo' | '_editable' | '_uid'>
    )> }
  )> }
);

export type SearchQueryVariables = Exact<{
  searchTerm: Scalars['String'];
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
}>;


export type SearchQuery = (
  { __typename?: 'QueryType' }
  & { ContentNodes?: Maybe<(
    { __typename?: 'ContentItems' }
    & Pick<ContentItems, 'total'>
    & { items?: Maybe<Array<Maybe<(
      { __typename?: 'ContentItem' }
      & Pick<ContentItem, 'full_slug' | 'meta_data' | 'name' | 'content'>
    )>>> }
  )> }
);

export type TermsAndConditionsQueryVariables = Exact<{ [key: string]: never; }>;


export type TermsAndConditionsQuery = (
  { __typename?: 'QueryType' }
  & { TermsandconditionsItems?: Maybe<(
    { __typename?: 'TermsandconditionsItems' }
    & { items?: Maybe<Array<Maybe<(
      { __typename?: 'TermsandconditionsItem' }
      & { content?: Maybe<(
        { __typename?: 'TermsandconditionsComponent' }
        & Pick<TermsandconditionsComponent, '_uid' | '_editable' | 'terms_and_conditions'>
      )> }
    )>>> }
  )> }
);


export const FaqDocument = gql`
    query faq($slug: ID!) {
  FaqItem(id: $slug) {
    content {
      title
      component
      questions
    }
    uuid
  }
}
    `;
export const FiltersItemsDocument = gql`
    query filtersItems {
  FiltersItems {
    items {
      uuid
      content {
        tag_groups
        _editable
        _uid
      }
    }
  }
}
    `;
export const FooterDocument = gql`
    query footer($slug: ID!) {
  FooterItem(id: $slug, resolve_links: "url") {
    published_at
    content {
      icon_links
      title
      link_list
      signup_cta
      input_placeholder
      signup_description
      _editable
    }
    uuid
  }
}
    `;
export const GuideDocument = gql`
    query guide($slug: ID!) {
  GuideItem(id: $slug, resolve_links: "url") {
    content {
      body
      seo
      _editable
    }
    uuid
  }
}
    `;
export const HomeDocument = gql`
    query home($slug: ID!) {
  HomeItem(id: $slug, resolve_links: "url") {
    content {
      title
      description
      cards
      seo
      _editable
    }
    uuid
  }
}
    `;
export const IntegrationDocument = gql`
    query integration($slug: ID!) {
  IntegrationpageItem(id: $slug) {
    slug
    content {
      title
      description
      bg {
        alt
        filename
        focus
      }
      _editable
      _uid
    }
    uuid
  }
}
    `;
export const IntegrationsDocument = gql`
    query integrations {
  IntegrationpageItems(sort_by: "content.title:asc") {
    items {
      slug
      full_slug
      content {
        seo
        title
        shortDescription
        bg {
          alt
          filename
          focus
        }
      }
    }
  }
}
    `;
export const IntegrationsOverviewDocument = gql`
    query integrationsOverview($slug: ID!) {
  IntegrationsoverviewItem(id: $slug) {
    id
    name
    slug
    uuid
    content {
      title
      description
      call_to_action
      seo
      _editable
      _uid
    }
  }
}
    `;
export const NavigationDocument = gql`
    query navigation($slug: ID!) {
  NavigationItem(id: $slug, resolve_links: "url") {
    published_at
    content {
      link_list
      guides
      links
      _editable
    }
    uuid
  }
}
    `;
export const ProductByIdDocument = gql`
    query productById($id: ID!) {
  ProductItem(id: $id) {
    content {
      _editable
      featured_products
      featured_products_title
    }
    uuid
    slug
  }
}
    `;
export const ProductUpdatesDocument = gql`
    query productUpdates($page: Int, $perPage: Int) {
  ProductupdateItems(
    page: $page
    per_page: $perPage
    sort_by: "content.date:desc"
  ) {
    items {
      uuid
      content {
        title
        date
        body
      }
    }
  }
}
    `;
export const ProductUpdatesOverviewDocument = gql`
    query productUpdatesOverview($slug: ID!) {
  ProductupdatesoverviewItem(id: $slug) {
    content {
      title
      seo
      _uid
    }
  }
}
    `;
export const ProductsDocument = gql`
    query products($slug: ID!) {
  ProductlistingItem(id: $slug) {
    id
    name
    slug
    uuid
    content {
      title
      description
      seo
      _editable
      _uid
    }
  }
}
    `;
export const SearchDocument = gql`
    query search($searchTerm: String!, $page: Int, $perPage: Int) {
  ContentNodes(search_term: $searchTerm, page: $page, per_page: $perPage) {
    items {
      full_slug
      meta_data
      name
      content
    }
    total
  }
}
    `;
export const TermsAndConditionsDocument = gql`
    query termsAndConditions {
  TermsandconditionsItems {
    items {
      content {
        _uid
        _editable
        terms_and_conditions
      }
    }
  }
}
    `;

export type SdkFunctionWrapper = <T>(action: (requestHeaders?:Record<string, string>) => Promise<T>, operationName: string) => Promise<T>;


const defaultWrapper: SdkFunctionWrapper = (action, _operationName) => action();

export function getSdk(client: GraphQLClient, withWrapper: SdkFunctionWrapper = defaultWrapper) {
  return {
    faq(variables: FaqQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<FaqQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<FaqQuery>(FaqDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'faq');
    },
    filtersItems(variables?: FiltersItemsQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<FiltersItemsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<FiltersItemsQuery>(FiltersItemsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'filtersItems');
    },
    footer(variables: FooterQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<FooterQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<FooterQuery>(FooterDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'footer');
    },
    guide(variables: GuideQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<GuideQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GuideQuery>(GuideDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'guide');
    },
    home(variables: HomeQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<HomeQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<HomeQuery>(HomeDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'home');
    },
    integration(variables: IntegrationQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<IntegrationQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<IntegrationQuery>(IntegrationDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'integration');
    },
    integrations(variables?: IntegrationsQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<IntegrationsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<IntegrationsQuery>(IntegrationsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'integrations');
    },
    integrationsOverview(variables: IntegrationsOverviewQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<IntegrationsOverviewQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<IntegrationsOverviewQuery>(IntegrationsOverviewDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'integrationsOverview');
    },
    navigation(variables: NavigationQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<NavigationQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<NavigationQuery>(NavigationDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'navigation');
    },
    productById(variables: ProductByIdQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<ProductByIdQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<ProductByIdQuery>(ProductByIdDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'productById');
    },
    productUpdates(variables?: ProductUpdatesQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<ProductUpdatesQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<ProductUpdatesQuery>(ProductUpdatesDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'productUpdates');
    },
    productUpdatesOverview(variables: ProductUpdatesOverviewQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<ProductUpdatesOverviewQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<ProductUpdatesOverviewQuery>(ProductUpdatesOverviewDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'productUpdatesOverview');
    },
    products(variables: ProductsQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<ProductsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<ProductsQuery>(ProductsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'products');
    },
    search(variables: SearchQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<SearchQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<SearchQuery>(SearchDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'search');
    },
    termsAndConditions(variables?: TermsAndConditionsQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<TermsAndConditionsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<TermsAndConditionsQuery>(TermsAndConditionsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'termsAndConditions');
    }
  };
}
export type Sdk = ReturnType<typeof getSdk>;