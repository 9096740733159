import { useRef, useEffect } from 'react';
import { Flex, css, Icon } from '@storyofams/react-ui';
import { useRouter } from 'next/router';
import styled from 'styled-components';

import { Button } from '~components/common/Button';
import { Heading } from '~components/common/Heading';
import { Text } from '~components/common/Text';
import { ContentItem } from '~lib/storyblok/sdk';
import { ExternalLink } from '../Icons';

const StyledButton = styled(Button).attrs({ variant: 'unstyled' })`
  width: 100%;
  border-radius: 8px;
  padding: 12px;

  &:hover,
  &:active,
  &:focus {
    background-color: ${({ theme }) => theme.colors.primary};

    h5,
    p {
      color: white;
    }

    svg {
      stroke: white;
    }
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    padding-left: 0px;
    padding-right: 0px;
    padding-top: 12px;
    padding-bottom: 12px;

    border-bottom: solid 1px;
    border-radius: 0px;
    border-color: ${({ theme }) => theme.colors.grey50};

    &:hover,
    &:active,
    &:focus {
      background-color: white;

      h5 {
        color: ${({ theme }) => theme.colors.primary};
      }

      p {
        color: ${({ theme }) => theme.colors.grey800};
      }

      svg {
        stroke: ${({ theme }) => theme.colors.grey400};
      }
    }
  }
`;

type ResultCardProps = {
  result: ContentItem;
};

export const ResultCard = ({ result }: ResultCardProps) => {
  const breadcrumbs = result?.full_slug?.split('/');

  const description =
    result?.content?.seo?.description.length > 123
      ? `${result?.content?.seo?.description.substring(0, 123)}...`
      : result?.content?.seo?.description;

  const labels = [
    { slug: 'integrations', label: 'Integration' },
    { slug: 'guides', label: 'Guide' },
    { slug: 'faq', label: 'FAQ' },
    { slug: 'product-updates', label: 'Product Update' },
  ];

  const label = labels.find((l) => l.slug === breadcrumbs[0])?.label;
  const href = `${!result?.full_slug?.startsWith('/') ? '/' : ''}${
    result?.full_slug
  }`;

  return (
    <StyledButton position="relative" href={href}>
      <Button
        variant="unstyled"
        as="a"
        target="_blank"
        href={href}
        position="absolute"
        top={1.5}
        right={1.5}
        display={['none', 'block']}
      >
        <Icon icon={ExternalLink} fontSize={3} stroke="grey400" />
      </Button>
      <Flex
        flexDirection="column"
        justifyContent="start"
        alignItems="start"
        width="100%"
      >
        {label && (
          <Flex
            alignItems="center"
            justifyContent="center"
            backgroundColor="#D8CEFF"
            color="primary"
            py={0.75}
            px={1}
            fontSize={1.5}
            lineHeight={1}
            fontWeight="bold"
            borderRadius="sm"
            css={css({ textTransform: 'capitalize' })}
            mb={1}
          >
            {label}
          </Flex>
        )}
        <Heading variant="h5" as="h5" fontWeight="bold" color="primary" mb={1}>
          {result.name}
        </Heading>
        <Text textAlign="left" mb={1}>
          {description}
        </Text>
        <Flex alignItems="center">
          {breadcrumbs?.length > 0 &&
            breadcrumbs.map((breadcrumb, i) => (
              <Text
                fontSize={1.5}
                color="grey200"
                css={css({ textTransform: 'capitalize' })}
              >
                {breadcrumb}
                {i + 1 !== breadcrumbs.length && ' > '}
              </Text>
            ))}
        </Flex>
      </Flex>
    </StyledButton>
  );
};
