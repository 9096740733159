import { useState, useEffect } from 'react';
import { Box, Flex } from '@storyofams/react-ui';
import { omit } from 'lodash';
import { useRouter } from 'next/router';
import qs from 'qs';
import { useQuery } from 'react-query';

import { SearchInput, Button, Text } from '~components';
import { fetchResults } from '~lib';
import { ResultCard } from './ResultCard';

export const SearchBar = () => {
  const router = useRouter();
  const [search, setSearch] = useState<string>();
  const [showDropdown, setShowDropdown] = useState(false);

  const { data: results, isLoading } = useQuery(
    ['search', search],
    () =>
      fetchResults({
        searchTerm: search,
        perPage: 4,
        page: 1,
      }),
    { enabled: search?.length > 0 },
  );

  const hasMoreItems = results?.moreItems > 0;

  useEffect(() => {
    setShowDropdown(search?.length > 0);
  }, [search?.length]);

  const updateSearch = (searchTerm: string) => {
    router?.replace(
      {
        pathname: '/search',
        query: qs.stringify(
          !searchTerm
            ? { ...omit(router?.query, ['search']) }
            : { ...router?.query, search: searchTerm },
        ),
      },
      undefined,
      {
        shallow: true,
      },
    );
  };

  return (
    <Flex
      overflow="visible"
      mx={3}
      justifyContent="center"
      flex={1}
      as="form"
      onSubmit={(e) => {
        e.preventDefault();
        updateSearch(search);
        setShowDropdown(false);
      }}
    >
      <SearchInput setSearch={setSearch} isLoading={isLoading}>
        {search?.length > 0 && showDropdown && (
          <Box
            position={['fixed', 'absolute']}
            top={['83px', 6]}
            backgroundColor="white"
            left={0}
            p={1.5}
            maxWidth={568}
            width={['100vw', '100%']}
            borderRadius={['0px 0px 8px 8px', 'md']}
            border="1px"
            borderColor="grey50"
            boxShadow="0px 10px 20px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04)"
          >
            {results?.items?.map((result) => (
              <ResultCard result={result} />
            ))}
            <Flex
              alignItems="center"
              justifyContent="center"
              mt={[2, 1.5]}
              mb={[0.5, 1.5]}
            >
              <Button
                variant="unstyled"
                onClick={() =>
                  router.push({
                    pathname: '/search',
                    query: {
                      search,
                    },
                  })
                }
              >
                <Text
                  fontSize={2}
                  fontWeight={hasMoreItems && 'bold'}
                  color={hasMoreItems ? 'primary' : 'grey200'}
                  textDecoration={hasMoreItems && 'underline'}
                >
                  {results?.items?.length === 0
                    ? 'No results'
                    : `${
                        hasMoreItems ? results?.moreItems : 'No'
                      } more result(s)`}
                </Text>
              </Button>
            </Flex>
          </Box>
        )}
      </SearchInput>
    </Flex>
  );
};
