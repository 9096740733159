import { ReactNode, CSSProperties } from 'react';
import { Stack, Box, SystemProps } from '@storyofams/react-ui';
import { ResponsiveValue } from 'styled-system';

type ContainerProps = {
  children: ReactNode;
  as?: 'section' | 'footer';
  withOverflowHidden?: boolean;
  bg?: SystemProps['bg'];
  space?: ResponsiveValue<CSSProperties['margin']>;
  flexDirection?: any;
} & Omit<SystemProps, 'flexDirection'>;

const ContainerBox = ({
  children,
  // When setting up: change the space according to the design
  space = [0, 0],
  flexDirection = 'column',
  ...props
}: ContainerProps) => {
  return (
    <Stack
      maxWidth="maxWidth"
      // px={{ all: 3, md: 8, lg: 15 }}
      // pt={[4, 8, 10]}
      // pb={[8, 10, 15]}
      mx={{ all: 'auto', lg: 5 }}
      width="100%"
      space={space}
      flexDirection={flexDirection}
      {...props}
    >
      {children}
    </Stack>
  );
};

export const Container = ({
  children,
  as,
  bg,
  withOverflowHidden = false,
  ...props
}: ContainerProps) => {
  if (withOverflowHidden || bg) {
    return (
      <Box overflowX="hidden" bg={bg} as={as}>
        <ContainerBox {...props}>{children}</ContainerBox>
      </Box>
    );
  }

  return (
    <ContainerBox as={as} {...props}>
      {children}
    </ContainerBox>
  );
};
