import { Box, system, SystemProps } from '@storyofams/react-ui';
import styled from 'styled-components';
import { variant, ResponsiveValue } from 'styled-system';

export const headingVariants = {
  h1: {
    fontSize: ['heading'],
    fontWeight: 'bold',
  },
  h2: {
    fontSize: [4],
    fontWeight: 'bold',
  },
  h3: {
    fontSize: [3],
  },
  h4: {
    fontSize: [2.5],
    fontWeight: 'medium',
  },
  h5: {
    fontSize: [2],
  },
  h6: {
    fontSize: [2],
  },
};

export type CustomProps = {
  variant:
    | ResponsiveValue<keyof typeof headingVariants>
    | keyof typeof headingVariants;
  as?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
} & SystemProps;

export const Heading = styled(Box).attrs((props: CustomProps) => ({
  as: props?.as ?? 'h1',
}))<CustomProps>`
  font-family: ${(p) => p.theme.fonts.body};
  line-height: ${(p) => p.theme.lineHeights.normal};
  font-weight: ${(p) => p.theme.fontWeights.medium};
  color: ${(p) => p.theme.colors.grey800};

  ${variant({ variants: headingVariants })}
  ${system};
`;
