import { useState } from 'react';
import { Box, Spinner } from '@storyofams/react-ui';
import { debounce } from 'lodash';
import styled from 'styled-components';

import { Search } from '../Icons';
import { Input } from '../Input';

const PurpleSpinner = styled(Spinner)`
  color: ${({ theme }) => theme.colors.primary};
`;

export const SearchInput = ({ setSearch, isLoading, children }) => {
  const [value, setValue] = useState<string>('');

  const debouncedSetSearch = debounce(setSearch, 100);

  const onChange = (e) => {
    setValue(e.target.value);
    debouncedSetSearch(e.target.value);
  };

  return (
    <Box
      fontSize={1.75}
      maxWidth={568}
      width="100%"
      borderRadius="xs"
      position="relative"
    >
      <Box>
        <Input
          name="search-nav-desktop"
          placeholder="Search for anything..."
          display={['none', 'block']}
          icon={isLoading ? PurpleSpinner : Search}
          value={value}
          onChange={onChange}
        />
        <Input
          name="search-nav-mobile"
          placeholder="Search..."
          display={['block', 'none']}
          icon={isLoading ? PurpleSpinner : Search}
          value={value}
          onChange={onChange}
        />
      </Box>
      {children}
    </Box>
  );
};
