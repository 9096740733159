import { Box, Flex } from '@storyofams/react-ui';
import { format } from 'date-fns';
import Image from 'next/image';
import SbEditable from 'storyblok-react';

import { Divider, Text, Heading, Button } from '~components';
import { getLinkProps } from '~lib';
import { FooterItem } from '~lib/storyblok/sdk';
import { NewsletterForm } from './NewsletterForm';

export const Footer = ({ content }: FooterItem) => {
  return (
    <Box width="100%">
      <Divider color="grey100" my={5} />
      <SbEditable content={(content as any) || {}}>
        <Flex
          width="100%"
          justifyContent="space-between"
          flexDirection={['column', 'row']}
        >
          <Flex
            width={['100%', '50%', '50%', '33%']}
            justifyContent={{
              all: 'space-between',
              l: 'start',
              xl: 'space-between',
            }}
            flexDirection={['column', 'column', 'row']}
          >
            <Box mb={[1, 1, 0]} mr={{ all: 0, l: 3, xl: 0 }}>
              {content?.icon_links?.map((link) => {
                const html = link?.icon?.content[0]?.content[0]?.text;

                return (
                  <Flex mb={3} key={link?.label}>
                    <Box
                      dangerouslySetInnerHTML={{ __html: html }}
                      width="24px"
                      height="24px"
                      mr={2}
                    />
                    <Text mr={0.5} color="grey200">
                      {link?.description}
                    </Text>
                    <Button
                      variant="link"
                      textDecoration="none"
                      to={getLinkProps(link?.url)}
                      target={link?.target || 'unset'}
                    >
                      {link?.label}
                    </Button>
                  </Flex>
                );
              })}
            </Box>
            <Box mb={[1, 0]}>
              <Heading variant="h4" as="h4" mb={3} fontWeight="bold">
                {content?.title}
              </Heading>
              {content?.link_list?.map((link) => (
                <Button
                  key={link?.label}
                  variant="unstyled"
                  mb={3}
                  color="grey200"
                  to={getLinkProps(link?.url)}
                  display="block"
                >
                  {link?.label}
                </Button>
              ))}
            </Box>
          </Flex>

          <NewsletterForm
            cta={content?.signup_cta}
            placeholder={content?.input_placeholder}
            description={content?.signup_description}
          />
        </Flex>

        <Divider color="grey100" my={5} />

       
      </SbEditable>
    </Box>
  );
};
