import { FC } from 'react';
import { css, SystemProps } from '@storyofams/react-ui';
import { Button } from '~components';

interface NavigationLinkProps extends SystemProps {
  link: string;
  active?: boolean;
  target?: '' | 'blank';
}

export const NavigationLink: FC<NavigationLinkProps> = ({
  link,
  active,
  children,
  target,
  ...props
}) => {
  return (
    <Button
      // @ts-ignore
      forwardedAs="a" // this doesn't provide the typing but relays the props
      // as="a" // this provides the typing but fails to relay the props
      target={target}
      variant="menuItem"
      to={link}
      display="flex"
      height={['auto', 'auto', '100%']}
      justifyContent="left"
      fontWeight="medium"
      width={['100%', '100%', 'auto']}
      fontSize={2}
      textAlign="left"
      css={css({
        '&:after': {
          content: "''",
          position: 'absolute',
          left: active ? 0 : '50%',
          right: active ? 0 : '50%',
          bottom: 0,
          height: '4px',
          backgroundColor: 'primary700',
          transition: 'left 0.18s ease-in-out, right 0.18s ease-in-out',
          opacity: [0, 0, active ? 1 : 0],
        },
      })}
      {...props}
    >
      {children}
    </Button>
  );
};
