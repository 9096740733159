import { FC } from 'react';

import {
  Icon,
  Search,
  Flex,
  Box,
  SystemProps,
  css,
} from '@storyofams/react-ui';
import { Text } from '~components/common/Text';
import { Cross } from '../../Icons';

interface NavigationPrimaryProps extends SystemProps {
  hasSearchButton?: boolean;
  mobileMenuOpen: boolean;
  setMobileMenuOpen(boolean): void;
}

export const NavigationPrimary: FC<NavigationPrimaryProps> = ({
  children,
  hasSearchButton,
  mobileMenuOpen,
  setMobileMenuOpen,
  ...props
}) => {
  return (
    <>
      <Flex
        mx={'auto' as any}
        height={['52px', '52px', '52px', '80px']}
        maxWidth="maxWidth"
        alignItems="center"
        as="nav"
        {...props}
      >
        <Box
          position={['fixed', 'fixed', 'fixed', 'static']}
          display={[
            'flex !important',
            'flex !important',
            'flex !important',
            'none !important',
          ]}
          top={[0, 0, 0, 'unset']}
          left={[
            mobileMenuOpen ? 0 : '-100%',
            mobileMenuOpen ? 0 : '-100%',
            mobileMenuOpen ? 0 : '-100%',
            'unset',
          ]}
          bottom={[0, 0, 0, 'unset']}
          width="100%"
          backgroundColor={'rgba(45,45,45,.65)' as any}
          zIndex={'overlay' as any}
          transition="opacity .24s ease-out"
          opacity={[
            mobileMenuOpen ? 1 : 0,
            mobileMenuOpen ? 1 : 0,
            mobileMenuOpen ? 1 : 0,
            0,
          ]}
          onClick={() => setMobileMenuOpen(false)}
        />

        <Box
          position={['fixed', 'fixed', 'fixed', 'static']}
          top={[0, 0, 0, 'unset']}
          right={[
            mobileMenuOpen ? 0 : '-100%',
            mobileMenuOpen ? 0 : '-100%',
            mobileMenuOpen ? 0 : '-100%',
            'unset',
          ]}
          bottom={[0, 0, 0, 'unset']}
          flex={1}
          backgroundColor="white"
          zIndex={'overlay' as any}
          transition="left .24s ease-out"
          maxWidth={['320px', '320px', '320px', 'none']}
          height="100%"
          width="100%"
        >
          <Box
            width="100%"
            height="85px"
            display={['flex', 'flex', 'flex', 'none']}
            alignItems="center"
            justifyContent="space-between"
            as="button"
            borderBottom="1px"
            marginBottom={3}
            borderBottomColor="grey100"
            onClick={() => setMobileMenuOpen(false)}
            px={[4, 4, 4, 0]}
          >
            <Text variant="kicker">Menu</Text>

            <Flex
              as="button"
              justifyContent="center"
              alignItems="center"
              width="40px"
              height="40px"
              border="1px"
              borderRadius="sm"
              borderColor="grey100"
            >
              <Icon icon={Cross} />
            </Flex>
          </Box>

          <Box px={[3, 3, 3, 0]}>{children}</Box>
        </Box>

        <Flex alignItems="center" position="relative">
          {hasSearchButton && (
            <Flex
              as="button"
              minWidth="40px"
              height="40px"
              alignItems="center"
              justifyContent="center"
              borderRadius="xs"
              mr={1}
              transition="0.18s background-color ease-in-out"
              css={css({
                '&:hover': { backgroundColor: 'primary100' },
              })}
            >
              <Icon icon={Search} color="primary900" fontSize={3} />
            </Flex>
          )}
        </Flex>
      </Flex>
    </>
  );
};
