import React, { forwardRef, ComponentProps, ReactNode, Ref } from 'react';
import { Icon, SystemProps, Box, Spinner, system } from '@storyofams/react-ui';
import Link from 'next/link';
import type { PolymorphicForwardRefExoticComponent } from 'react-polymorphic-types';
import styled, { css } from 'styled-components';
import { variant } from 'styled-system';

const _defaultElement = 'button';

const baseStyles = {
  fontSize: 2,
  px: 4,
  py: 2,
  height: '48px',
};

const variants = {
  primary: {
    ...baseStyles,
    color: 'primary',
    border: '2px solid',
    borderColor: 'primary',
    fontWeight: 'bold',

    '&:not(:disabled)': {
      '&:hover, &:active': {
        bg: 'primary',
        color: 'white',
      },
    },
  },
  secondary: {
    ...baseStyles,
    color: 'white',
    border: '2px solid',
    borderColor: 'white',

    '&:not(:disabled)': {
      '&:hover, &:active': {
        backgroundColor: 'white',
        color: 'grey800',
      },
    },
  },
  link: {
    fontSize: 2,
    textDecoration: 'underline',
    color: 'primary',
  },
  kicker: {
    fontSize: 1.5,
    textTransform: 'uppercase',
    lineHeight: 1,
  },
  menuItem: {
    fontSize: 2,
    color: 'grey600',
  },
  unstyled: {
    '&:disabled': { cursor: 'not-allowed', color: 'grey500' },
  },
};

export type ButtonProps = {
  isLoading?: boolean;
  icon?: ComponentProps<typeof Icon>['icon'];
  href?: string;
  rel?: string;
  target?: string;
  to?: string;
  variant?: keyof typeof variants;
  disabled?: boolean;
  children: ReactNode;
};

const StyledButton = styled(Box)<Pick<ButtonProps, 'variant'>>`
  position: relative;
  appearance: none;
  text-decoration: none;

  ${(p) =>
    p.variant !== 'unstyled' &&
    css`
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      font-size: 16px;
      text-align: center;
      border: 0;
      border-radius: 8px;
      user-select: none;
      transition: background-color 0.18s ease-in-out, box-shadow 0.18s,
        border-color 0.18s ease-in-out, color 0.18s ease-in-out,
        opacity 0.18s ease-in-out, color 0.18s ease-in-out;

      &:active,
      &:focus {
        boxshadow: 0px 0px 0px 4px #d9e1dd;
      }

      &:disabled {
        cursor: not-allowed;
        opacity: 0.25;
      }

      &[data-is-loading] {
        cursor: wait;
        opacity: 1;
      }
    `}

  ${variant({ variants })}
  ${system}
`;

const loadingStyles = {
  position: 'relative' as any,
  disabled: true,
  'data-is-loading': true,
  'aria-disabled': true,
};

export const Button: PolymorphicForwardRefExoticComponent<
  ButtonProps & SystemProps,
  typeof _defaultElement
> = forwardRef(
  ({ children, icon, to, isLoading, ...props }: ButtonProps, ref: Ref<any>) => {
    const content = (
      <>
        {!!icon && <Icon width="24px" icon={icon} mr={1} />}
        {children}
      </>
    );

    return to ? (
      <Link href={to} passHref>
        <StyledButton as="a" variant="link" {...props}>
          {content}
        </StyledButton>
      </Link>
    ) : (
      <StyledButton
        as={props?.href ? 'a' : _defaultElement}
        variant={props?.href ? 'link' : 'primary'}
        display="flex"
        {...props}
        {...(isLoading ? loadingStyles : {})}
        ref={ref}
      >
        {isLoading ? (
          <Box
            position="absolute"
            top="50%"
            left="50%"
            transform="translate(-50%, -50%)"
          >
            <Spinner color="inherit" size={18 as any} />
          </Box>
        ) : (
          content
        )}
      </StyledButton>
    );
  },
);
