import { FC } from 'react';
import { Box, SystemProps } from '@storyofams/react-ui';

interface NavigationProps extends SystemProps {}

export const Navigation: FC<NavigationProps> = ({ children, ...props }) => {
  return (
    <Box
      position="sticky"
      top={'124px'}
      left={0}
      right={0}
      height={'calc(100vh - 124px)'}
      zIndex={'sticky' as any}
      backgroundColor="white"
      overflowY="auto"
      padding="3px"
      {...props}
    >
      <Box width="100%" position="relative">
        {children}
      </Box>
    </Box>
  );
};
